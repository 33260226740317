import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { APIBASEURL } from "../../../config/service/ApiURL";
import { FecthPost, FecthPostAsset } from "../../../config/service/fecth";

const AddMember = () => {
  const [selectedImage, setSelectedImage] = useState();
  const [imageDirectory, setImageDirectory] = useState("");
  const [preview, setPreview] = useState("");
  const [FormData, setFormData] = useState({
    name: "",
    age: "",
    gender: "",
    height: "",
    weight: "",
    phone: "",
    email: "",
    password: "",
    status: "Active",
  });
  const navigate = useNavigate();
  const URL = `${APIBASEURL}/member`;

  const handleChangeImage = (e) => {
    setSelectedImage(e.target.files[0]);
    setImageDirectory(e.target.value);
  };

  const handleAddMember = async (e) => {
    e.preventDefault();

    const image = await FecthPostAsset(selectedImage, imageDirectory);
    if (selectedImage) FormData.photo = image;

    const response = await FecthPost(URL, FormData);
    if (response) return navigate("/member", { replace: true });
  };

  useEffect(() => {
    if (selectedImage !== undefined) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  }, [selectedImage, preview]);

  return (
    <div>
      <Link type="submit" to={`/member`} className="button-orange">
        <IoArrowBackOutline className="text-3xl" />
        Back
      </Link>
      <form
        onSubmit={(e) => handleAddMember(e)}
        className="p-8 bg-white shadow-lg rounded-lg lg:w-[50rem] w-full"
      >
        <h1 className="text-2xl font-semibold mb-8 text-secondary">
          Create New Member
        </h1>
        <div className="preview-img">
          <img src={preview} />
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 ">
          <div className="input-form-box">
            <label htmlFor="name">Name : </label>
            <input
              type="text"
              id="name"
              onChange={(e) =>
                setFormData({ ...FormData, name: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="age">Age : </label>
            <input
              type="number"
              id="age"
              onChange={(e) =>
                setFormData({ ...FormData, age: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="gender">gender : </label>
            <input
              type="text"
              id="gender"
              onChange={(e) =>
                setFormData({ ...FormData, gender: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="height">Height : </label>
            <input
              type="number"
              id="height"
              onChange={(e) =>
                setFormData({ ...FormData, height: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="weigth">Weigth : </label>
            <input
              type="number"
              id="weigth"
              onChange={(e) =>
                setFormData({ ...FormData, weight: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="email">Email : </label>
            <input
              type="email"
              id="email"
              onChange={(e) =>
                setFormData({ ...FormData, email: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="phone">Phone : </label>
            <input
              type="number"
              id="phone"
              onChange={(e) =>
                setFormData({ ...FormData, phone: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="password">password : </label>
            <input
              type="password"
              id="password"
              onChange={(e) =>
                setFormData({ ...FormData, password: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="status">Status :</label>
            <select
              name="status"
              id="status"
              onChange={(e) =>
                setFormData({ ...FormData, status: e.target.value })
              }
            >
              <option value="Active">Active</option>
              <option value="Non Active">Non Active</option>
            </select>
          </div>
          <div className="input-form-box">
            <label htmlFor="photo">Photo : </label>
            <input
              type="file"
              accept="image/*"
              id="photo"
              onChange={handleChangeImage}
            />
          </div>
        </div>
        <button type="submit" className="button-orange">
          Add
        </button>
      </form>
    </div>
  );
};

export default AddMember;
