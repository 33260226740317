import React, { useState } from "react";
import { useEffect } from "react";
import { Loading } from "../../../components";
import { APIBASEURL, GetData } from "../../../config/service/ApiURL";

const ReportActivity = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [FormData, setFormData] = useState({
    companyId: "",
    coachId: "",
    from_date: "",
    to_date: "",
  });
  const dataCompany = GetData(`company`);
  const dataCoach = GetData("coach");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (FormData.companyId == "") {
      FormData.companyId = dataCompany[0].id;
    }

    let url = `${APIBASEURL}/report/activity?from_date=${FormData.from_date}&to_date=${FormData.to_date}&company_id=${FormData.companyId}`;
    if (FormData.coachId != "") url = `${url}&coach_id=${FormData.coachId}`;
    window.open(url);
  };

  const load = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  useEffect(() => {
    if (dataCompany) load();
  }, []);

  return (
    <div>
      {isLoading && <Loading />}{" "}
      {!isLoading && (
        <div>
          <h4 className="text-primary mb-10 font-semibold text-lg">
            Activity Report
          </h4>
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="lg:w-[50rem] w-full"
          >
            <div className="grid grid-cols-2  gap-5">
              <div className="input-form-box -my-3 mb-4">
                <label htmlFor="company">Company</label>
                <select
                  name="company"
                  id="company"
                  required
                  onChange={(e) =>
                    setFormData({ ...FormData, companyId: e.target.value })
                  }
                >
                  {dataCompany != undefined
                    ? dataCompany.map((company) => (
                        <option value={company.id} key={company.id}>
                          {company.name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
              <div className="input-form-box -my-3 mb-4">
                <label htmlFor="coach">Coach</label>
                <select
                  name="coach"
                  id="coach"
                  onChange={(e) => {
                    setFormData({ ...FormData, coachId: e.target.value });
                  }}
                >
                  <option value="">no coach selected</option>
                  {dataCoach != undefined
                    ? dataCoach.map((coach) => (
                        <option value={coach.id} key={coach.id}>
                          {coach.name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
            </div>
            <div className="grid grid-cols-2  gap-5">
              <div className="input-form-box">
                <label htmlFor="from_date">From Date : </label>
                <input
                  type="date"
                  id="date"
                  required
                  onChange={(e) =>
                    setFormData({ ...FormData, from_date: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="to_date">To Date : </label>
                <input
                  type="date"
                  id="date"
                  required
                  onChange={(e) =>
                    setFormData({ ...FormData, to_date: e.target.value })
                  }
                />
              </div>
            </div>
            <button
              type="submit"
              className="button bg-success"
              target="_blank"
              to=""
              rel="noopener noreferrer"
            >
              Generate Report
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ReportActivity;
