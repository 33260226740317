import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Loading } from "../../../components";
import { DeleteData } from "../../../config/service/ApiURL";

const DeleteTopic = () => {
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const deleteTopic = DeleteData(`topic/${params.topicId}`);

  useEffect(() => {
    if (deleteTopic) {
      setIsLoading(true);
      navigate("/topic");
    }
  }, []);

  return <div>{isLoading && <Loading />}</div>;
};

export default DeleteTopic;
