import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import { APIBASEURL } from "../../../config/service/ApiURL";
import { FecthPost, FecthPostAsset } from "../../../config/service/fecth";

const AddArticle = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [imageDirectory, setImageDirectory] = useState("");
  const [preview, setPreview] = useState("");
  const [FormData, setFormData] = useState({
    title: "",
    short_desc: "",
    article_date: "",
    url: "",
    image: "",
  });
  const navigate = useNavigate();
  const params = useParams();
  const URL = `${APIBASEURL}/article`;

  const handleChangeImage = (e) => {
    setSelectedImage(e.target.files[0]);
    setImageDirectory(e.target.value);
  };

  const handleAddArticle = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    // const image = await FecthPostAsset(selectedImage, imageDirectory);
    // if (selectedImage) FormData.image = image;

    const response = await FecthPost(URL, FormData);
    if (response) navigate("/article");
  };

  useEffect(() => {
    // if (selectedImage !== undefined) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setPreview(reader.result);
    //   };
    //   reader.readAsDataURL(selectedImage);
    // }
  }, [selectedImage, preview]);

  return (
    <div>
      <h4 className="text-primary font-semibold text-lg mb-8">Article - Add</h4>

      <form
        onSubmit={(e) => handleAddArticle(e)}
        className=" lg:w-[50rem] w-full"
      >
        {/* <div className="preview-img">
          <img src={preview} />
        </div> */}

        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
          <div className="input-form-box">
            <label htmlFor="title">Title : </label>
            <input
              type="text"
              id="title"
              placeholder="Name"
              onChange={(e) =>
                setFormData({ ...FormData, title: e.target.value })
              }
            />
          </div>

          <div className="input-form-box">
            <label htmlFor="image">Image : </label>
            <input
              type="text"
              id="image"
              placeholder="https://doktersehat.com/example-link"
              onChange={(e) =>
                setFormData({ ...FormData, image: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="url">URL : </label>
            <input
              type="text"
              id="url"
              placeholder="https://doktersehat.com/example-link"
              onChange={(e) =>
                setFormData({ ...FormData, url: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="article_date">Article Date : </label>
            <input
              type="date"
              id="article_date"
              placeholder="06-06-2022"
              onChange={(e) =>
                setFormData({ ...FormData, article_date: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="short_description">Short Description : </label>
            <textarea
              name="short_description"
              id="short_description"
              placeholder="Type"
              onChange={(e) =>
                setFormData({ ...FormData, short_desc: e.target.value })
              }
            ></textarea>
          </div>

          {/* 
          <div className="input-form-box">
            <label htmlFor="image">Image : </label>
            <input
              type="file"
              accept="image/*"
              id="image"
              onChange={handleChangeImage}
            />
          </div> */}
        </div>

        <div className="flex items-center gap-2">
          <Link type="submit" to="/article" className="button bg-danger">
            Cancel
          </Link>
          <button
            type="submit"
            className="button bg-success"
            // onClick={handleSaveCoach}
          >
            Save Article
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddArticle;
