import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APIBASEURL } from "../../../config/service/ApiURL";
import { FecthDelete } from "../../../config/service/fecth";

const DeleteMember = () => {
  const navigate = useNavigate();
  const params = useParams();
  const URL = `${APIBASEURL}/member/${params.memberId}`;
  const dataMember = FecthDelete(URL);

  useEffect(() => {
    if (dataMember) {
      navigate("/member", { replace: true });
      return;
    }
  });
};

export default DeleteMember;
