import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import { Loading } from "../../../components";
import { APIBASEURL } from "../../../config/service/ApiURL";
import { FecthById } from "../../../config/service/fecth";

const DetailCoach = () => {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const URL = `${APIBASEURL}/coach/` + params.coachId;
  const dataCoach = FecthById(URL);

  const load = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    if (dataCoach != []) load();
  }, []);

  return (
    <div>
      {loading && <Loading />}
      {!loading && (
        <div>
          <Link
            type="submit"
            to={`/coach`}
            className="button bg-secondary mb-6"
          >
            <IoArrowBackOutline className="text-3xl" />
            Back
          </Link>
          <h4 className="text-primary font-semibold text-lg">Coach</h4>
          <div className="w-full mt-5">
            <img
              src={dataCoach.photo}
              alt={"Photo : " + dataCoach.name}
              className="rounded-xl w-52 mb-4"
            />
            <p className="leading-relaxed mb-8 w-[45rem]">{dataCoach.about}</p>
            <ul className="flex flex-col gap-1  w-[20rem]">
              <li className="company-list-detail">
                <span>Name</span>
                {dataCoach.name}
              </li>
              <li className="company-list-detail">
                <span>Education</span>
                {dataCoach.education}
              </li>
              <li className="company-list-detail">
                <span>Type </span> {dataCoach.type}
              </li>
              <li className="company-list-detail">
                <span>Phone </span>
                {dataCoach.phone}
              </li>
              <li className="company-list-detail">
                <span>Email </span>
                {dataCoach.email}
              </li>
              <li className="company-list-detail">
                <span> Location </span>
                {dataCoach.location}
              </li>
              <li className="company-list-detail">
                <span> Status </span>
                {dataCoach.status}
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailCoach;
