import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../components";
import { APIBASEURL } from "../../../config/service/ApiURL";
import { FecthById, FecthUpdate } from "../../../config/service/fecth";

const EditArticle = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState();
  const [imageDirectory, setImageDirectory] = useState("");
  const [preview, setPreview] = useState("");
  const [photoOld, setPhotoOld] = useState(false);
  const [FormData, setFormData] = useState({
    title: "",
    short_desc: "",
    image: "",
    url: "",
    article_date: "",
  });
  const navigate = useNavigate();
  const params = useParams();
  const URL = `${APIBASEURL}/article/${params.articleId}`;
  const getDataArticle = FecthById(URL);

  const handleChangeImage = (e) => {
    setSelectedImage(e.target.files[0]);
    setImageDirectory(e.target.value);
  };

  const handleEditArticle = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    // const image = await FecthPostAsset(selectedImage, imageDirectory);
    // if (photoOld) FormData.image = getDataArticle.image;
    // if (selectedImage) FormData.image = image;

    const response = await FecthUpdate(URL, FormData);
    if (response) return navigate("/article");
  };

  const load = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  useEffect(() => {
    if (getDataArticle) load();

    // if (getDataArticle.image !== "" || getDataArticle.image !== undefined)
    //   setPhotoOld(true);
    // if (photoOld) setPreview(getDataArticle.image);

    // if (selectedImage !== undefined) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setPreview(reader.result);
    //   };
    //   reader.readAsDataURL(selectedImage);
    // }

    setFormData({
      title: getDataArticle.title,
      short_desc: getDataArticle.short_desc,
      image: getDataArticle.image,
      url: getDataArticle.url,
      article_date: getDataArticle.article_date,
    });
  }, [selectedImage, preview, getDataArticle]);

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <h4 className="text-primary font-semibold text-lg mb-8">
            Article - Add
          </h4>

          <form
            onSubmit={(e) => handleEditArticle(e)}
            className=" lg:w-[50rem] w-full"
          >
            {/* <div className="preview-img">
    <img src={preview} />
  </div> */}

            <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
              <div className="input-form-box">
                <label htmlFor="title">Title : </label>
                <input
                  type="text"
                  id="title"
                  placeholder="Name"
                  value={FormData.title || ""}
                  onChange={(e) =>
                    setFormData({ ...FormData, title: e.target.value })
                  }
                />
              </div>

              <div className="input-form-box">
                <label htmlFor="image">Image : </label>
                <input
                  type="text"
                  id="image"
                  value={FormData.image || ""}
                  placeholder="https://doktersehat.com/example-link"
                  onChange={(e) =>
                    setFormData({ ...FormData, image: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="url">URL : </label>
                <input
                  type="text"
                  id="url"
                  placeholder="https://doktersehat.com/example-link"
                  value={FormData.url || ""}
                  onChange={(e) =>
                    setFormData({ ...FormData, url: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="article_date">Article Date : </label>
                <input
                  type="date"
                  id="article_date"
                  placeholder="06-06-2022"
                  value={FormData.article_date || ""}
                  onChange={(e) =>
                    setFormData({ ...FormData, article_date: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="short_description">Short Description : </label>
                <textarea
                  name="short_description"
                  id="short_description"
                  placeholder="Type"
                  value={FormData.short_desc || ""}
                  onChange={(e) =>
                    setFormData({ ...FormData, short_desc: e.target.value })
                  }
                ></textarea>
              </div>

              {/* 
    <div className="input-form-box">
      <label htmlFor="image">Image : </label>
      <input
        type="file"
        accept="image/*"
        id="image"
        onChange={handleChangeImage}
      />
    </div> */}
            </div>

            <div className="flex items-center gap-2">
              <Link type="submit" to="/article" className="button bg-danger">
                Cancel
              </Link>
              <button
                type="submit"
                className="button bg-success"
                // onClick={handleSaveCoach}
              >
                Save Article
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditArticle;
