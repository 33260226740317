import React, { useEffect, useState } from "react";
import { GoTrashcan } from "react-icons/go";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../components";
import { APIBASEURL } from "../../../config/service/ApiURL";
import {
  FecthGet,
  FecthPost,
  FecthPostAsset,
} from "../../../config/service/fecth";

const AddCoach = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [companyIsSelected, setCompanyIsSelected] = useState("");
  const [companyTrash, setCompanyTrash] = useState([]);
  const [companyIsAlready, setCompanyIsAlready] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [imageDirectory, setImageDirectory] = useState("");
  const [preview, setPreview] = useState("");
  const [FormData, setFormData] = useState({
    name: "",
    education: "Medical Doctor",
    phone: "",
    type: "Dietitian",
    location: "",
    status: "Active",
    email: "",
    photo: "",
    about: "",
    password: "",
  });
  const navigate = useNavigate();
  const params = useParams();
  const URL = `${APIBASEURL}/coach`;
  const dataCompany = FecthGet(`${APIBASEURL}/company`);

  const handleSaveCoach = async () => {
    setIsLoading(true);

    const image = await FecthPostAsset(selectedImage, imageDirectory);
    if (selectedImage) FormData.photo = image;

    const addCoach = await FecthPost(URL, FormData);
    if (addCoach) navigate("/coach", { replace: true });
    // companyData.forEach(async (coach) => {
    //   if (!coach.id) return;
    //   const res = await FecthPost(`${URL}/${coachId}/company`, {
    //     company_id: coach.id,
    //   });
    // });
  };

  const handleCompanyIsPick = (coachId) => {
    const index = companyData.findIndex((coach) => {
      return coachId === coach.id;
    });

    companyData.splice(index, 1);
    const newArr = companyData;

    setCompanyTrash([...companyTrash, newArr]);
  };

  const handleCooseCoach = (companyId) => {
    if (companyData.length > 4) return false;
    companyData.forEach((company) => {
      if (company.id === companyId) setCompanyIsAlready(true);
    });

    dataCompany.forEach((company) => {
      if (companyId === company.id) {
        setCompanyData([...companyData, company]);
      }
    });
  };

  const handleChangeImage = (e) => {
    setSelectedImage(e.target.files[0]);
    setImageDirectory(e.target.value);
  };

  const handleChange = () => {
    if (companyIsAlready) {
      companyData.pop();
      setCompanyIsAlready(false);
    }
  };

  useEffect(() => {
    if (selectedImage !== undefined) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  }, [selectedImage, preview]);

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <h4 className="text-primary font-semibold text-lg">Coach</h4>
          <div className="preview-img mt-8">
            <img src={preview} />
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleChange();
            }}
            className=" lg:w-[50rem] w-full -mt-[10px]"
          >
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
              <div className="input-form-box">
                <label htmlFor="name">Name : </label>
                <input
                  type="text"
                  id="name"
                  onChange={(e) =>
                    setFormData({ ...FormData, name: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="education">Education : </label>
                <select
                  name="type"
                  id="education"
                  onChange={(e) =>
                    setFormData({ ...FormData, education: e.target.value })
                  }
                >
                  <option value="Medical Doctor">Medical Doctor</option>
                  <option value="Master of Profesional Psychology">
                    Master of Profesional Psychology
                  </option>
                  <option value="Bachelor">Bachelor</option>
                </select>
              </div>

              <div className="input-form-box">
                <label htmlFor="phone">Phone : </label>
                <input
                  type="number"
                  id="phone"
                  onChange={(e) =>
                    setFormData({ ...FormData, phone: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="type">Type :</label>
                <select
                  name="type"
                  id="type"
                  onChange={(e) =>
                    setFormData({ ...FormData, type: e.target.value })
                  }
                >
                  <option value="Dietitian">Dietitian</option>
                  <option value="Psikolog">Psikolog</option>
                  <option value="Dokter">Dokter</option>
                  <option value="Fitness">Fitness</option>
                </select>
              </div>
              <div className="input-form-box">
                <label htmlFor="location">Location : </label>
                <input
                  type="text"
                  id="location"
                  onChange={(e) =>
                    setFormData({ ...FormData, location: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="status">Status :</label>
                <select
                  name="status"
                  id="status"
                  onChange={(e) =>
                    setFormData({ ...FormData, status: e.target.value })
                  }
                >
                  <option value="Active">Active</option>
                  <option value="Non Active">Non Active</option>
                </select>
              </div>
              <div className="input-form-box">
                <label htmlFor="email">Email : </label>
                <input
                  type="email"
                  id="email"
                  onChange={(e) =>
                    setFormData({ ...FormData, email: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="password">Password : </label>
                <input
                  type="password"
                  id="password"
                  onChange={(e) =>
                    setFormData({ ...FormData, password: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="photo">Photo : </label>
                <input
                  type="file"
                  accept="image/*"
                  id="photo"
                  onChange={handleChangeImage}
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="about">About : </label>
                <textarea
                  name="about"
                  id="about"
                  onChange={(e) =>
                    setFormData({ ...FormData, about: e.target.value })
                  }
                ></textarea>
              </div>
            </div>
            {/* <div className="mt-12 grid md:grid-cols-2 grid-cols-1 gap-5 items-end">
          <div className="input-form-box -my-5">
            <h3 className="font-bold text-sm mb-1">Company</h3>
            <label htmlFor="coach">Choose company to add</label>
            <select
              name="coach"
              id="coach"
              onChange={(e) => setCompanyIsSelected(e.target.value)}
            >
              {dataCompany != undefined
                ? dataCompany.map((company) => (
                    <option value={company.id} key={company.id}>
                      {company.name}
                    </option>
                  ))
                : ""}
            </select>
          </div>
          <button
            type="submit"
            onClick={() => handleCooseCoach(companyIsSelected)}
            className="w-20 h-10  text-center rounded-lg text-white  bg-success"
          >
            Add
          </button>
        </div>
        <div className="mt-4 grid md:grid-cols-2 grid-cols-1 gap-5">
          {companyData != []
            ? companyData.map((coach, index) => (
                <div
                  className="rounded-lg relative p-5 flex items-center gap-5 bg-bg-grey-young"
                  key={index}
                >
                  <div>
                    <h1 className="font-semibold text-sm">{coach.name}</h1>
                    <p className="text-sm">{coach.pic}</p>
                  </div>
                  <GoTrashcan
                    className="text-danger text-2xl absolute bottom-3 right-3 cursor-pointer"
                    onClick={() => handleCompanyIsPick(coach.id)}
                  />
                </div>
              ))
            : ""}
        </div> */}
            <div className="flex item-center gap-2">
              <Link type="submit" to="/coach" className="button bg-danger">
                Cancel
              </Link>
              <button
                type="submit"
                className="button bg-success"
                onClick={handleSaveCoach}
              >
                Save Coach
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddCoach;
