import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Loading } from "../../../components";
import { PostData } from "../../../config/service/ApiURL";

const AddMemberCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [FormData, setFormData] = useState({
    name: "",
  });
  const navigate = useNavigate();

  const handleSaveMemberCategory = () => {
    if (FormData.name === "") return;

    setIsLoading(true);
    const res = PostData("member_category", FormData);
    if (res) navigate("/member-category");
  };

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <h4 className="text-primary font-semibold text-lg">
            Member Category
          </h4>
          <form
            className="lg:w-[50rem] w-full mt-[45px]"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
              <div className="input-form-box">
                <label htmlFor="name">Name : </label>
                <input
                  type="text"
                  id="name"
                  placeholder="name"
                  required
                  onChange={(e) =>
                    setFormData({ ...FormData, name: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="flex item-center gap-2 -mt-[10px] ">
              <Link
                type="submit"
                to="/member-category"
                className="button bg-danger"
              >
                Cancel
              </Link>
              <button
                type="submit"
                className="button bg-success"
                onClick={handleSaveMemberCategory}
              >
                Save Member Category
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddMemberCategory;
