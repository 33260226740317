import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../components";
import { DeleteData } from "../../../config/service/ApiURL";

const DeleteCompany = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const deleteCompany = DeleteData(`company/${params.companyId}`);

  useEffect(() => {
    if (deleteCompany) {
      setIsLoading(true);
      navigate(`/company`);
    }
  }, []);

  return <>{isLoading && <Loading />}</>;
};

export default DeleteCompany;
