import React, { useEffect } from "react";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Navbar, Sidebar } from "../../components";
import { Routers } from "../../config";

const MainApp = () => {
  const [isLogin, setIslogin] = useState(false);
  const key = localStorage.getItem("key");
  const navigate = useNavigate();

  useEffect(() => {
    if (key === undefined || key === null) {
      navigate("/login", { replace: true });
    } else {
      setIslogin(true);
    }
  }, [navigate]);

  return (
    <div className="main-app-wrapper flex gap-5 h-screen">
      {isLogin && (
        <div className="siderbar-wrapper z-30">
          <Sidebar />
        </div>
      )}
      <div className="content-wrapper px-[18px] pt-[110px] pb-10  overflow-x-auto w-full">
        {isLogin && <Navbar />}
        <Routers />
      </div>
    </div>
  );
};

export default MainApp;
