import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Loading } from "../../../components";
import { PostData } from "../../../config/service/ApiURL";

const AddUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [menuPermission, setMenuPermission] = useState([]);
  const [FormData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    menu_permission: [],
  });
  const navigate = useNavigate();

  const handleChangeMenuPermission = (e) => {
    const value = e.target.value;
    if (e.target.checked) setMenuPermission([...menuPermission, value]);
    if (!e.target.checked) {
      const newMenuPermission = menuPermission.filter((menu) => menu != value);
      setMenuPermission(newMenuPermission);
    }
  };

  const handleSaveUser = async () => {
    setIsLoading(true);

    if (FormData.name == "" || FormData.email == "") return;
    const res = await PostData("user", FormData);
    if (res) navigate("/user-management");
  };

  useEffect(() => {
    setFormData({ ...FormData, menu_permission: menuPermission.join("|") });
  }, [menuPermission]);

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <h4 className="text-primary font-semibold text-lg">
            User Management
          </h4>
          <form
            className="lg:w-[50rem] w-full mt-[45px]"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
              <div className="input-form-box">
                <label htmlFor="name">Name : </label>
                <input
                  type="text"
                  id="name"
                  placeholder="name"
                  required
                  onChange={(e) =>
                    setFormData({ ...FormData, name: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="email">Email : </label>
                <input
                  type="email"
                  id="email"
                  placeholder="email"
                  required
                  onChange={(e) =>
                    setFormData({ ...FormData, email: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="password">Password : </label>
                <input
                  type="password"
                  id="password"
                  placeholder="password"
                  required
                  onChange={(e) =>
                    setFormData({ ...FormData, password: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box ml-2">
                <label htmlFor="name">Menu Permission : </label>
                <div className="flex gap-8">
                  <div className="flex flex-col gap-2">
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="company"
                        name="company"
                        value="company"
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="company" className="ml-2">
                        Company
                      </label>
                    </div>
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="members"
                        name="members"
                        value="members"
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="members" className="ml-2">
                        Members
                      </label>
                    </div>
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="coach"
                        name="coach"
                        value="coach"
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="coach" className="ml-2">
                        Coach
                      </label>
                    </div>
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="activity data"
                        name="activity data"
                        value="activity data"
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="activity data" className="ml-2">
                        Activity Data
                      </label>
                    </div>
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="report activity"
                        name="report activity"
                        value="report activity"
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="report activity" className="ml-2">
                        Report Acticity
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="article"
                        name="article"
                        value="article"
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="article" className="ml-2">
                        Article
                      </label>
                    </div>
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="catering healty"
                        name="catering healty"
                        value="catering healty"
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="catering healty" className="ml-2">
                        Catering Healthy
                      </label>
                    </div>
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="members category"
                        name="members category"
                        value="members category"
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="members category" className="ml-2">
                        Members Category
                      </label>
                    </div>
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="topic"
                        name="topic"
                        value="topic"
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="topic" className="ml-2">
                        Topic
                      </label>
                    </div>
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="user management"
                        name="user management"
                        value="user management"
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="user management" className="ml-2">
                        User Management
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex item-center gap-2 mt-4 ">
              <Link
                type="submit"
                to="/user-management"
                className="button bg-danger"
              >
                Cancel
              </Link>
              <button
                type="submit"
                className="button bg-success"
                onClick={handleSaveUser}
              >
                Save User
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default AddUser;
