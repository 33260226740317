import { useState, useEffect } from "react";
import { GetDataById } from "../../config/service/ApiURL";
import SidebarLink from "../SidebarLink/SidebarLink";

const Sidebar = () => {
  const [menuPermission, setMenuPermission] = useState({
    company: false,
    members: false,
    coach: false,
    activityData: false,
    reportActivity: false,
    article: false,
    cateringHealty: false,
    membersCategory: false,
    topic: false,
    userManagement: false,
  });

  const userIsLogin = GetDataById("auth/info");

  useEffect(() => {
    if (userIsLogin.menu_permission == undefined) return;
    if (!localStorage.getItem("permission")) {
      localStorage.setItem("permission", userIsLogin.menu_permission);
    }

    let newPermission = {};

    for (const menu of userIsLogin.menu_permission.split("|")) {
      switch (menu) {
        case "company":
          newPermission.company = true;
          break;
        case "members":
          newPermission.members = true;
          break;
        case "coach":
          newPermission.coach = true;
          break;
        case "activity data":
          newPermission.activityData = true;
          break;
        case "report activity":
          newPermission.reportActivity = true;
          break;
        case "article":
          newPermission.article = true;
          break;
        case "catering healty":
          newPermission.cateringHealty = true;
          break;
        case "members category":
          newPermission.membersCategory = true;
          break;
        case "topic":
          newPermission.topic = true;
          break;
        case "user management":
          newPermission.userManagement = true;
          break;
        default:
          newPermission.company = false;
          newPermission.members = false;
          newPermission.coach = false;
          newPermission.activityData = false;
          newPermission.reportActivity = false;
          newPermission.article = false;
          newPermission.cateringHealty = false;
          newPermission.membersCategory = false;
          newPermission.topic = false;
          newPermission.userManagement = false;
          break;
      }
    }

    setMenuPermission(newPermission);
  }, [userIsLogin]);

  return (
    <div className="bg-primary h-full w-[240px] pt-2 relative  ease-in duration-300 overflow-y-auto overflow-x-hidden pb-10">
      <div className="opacity-1 visible transition-opacity duration-300 ">
        <div className="avatar-info flex items-center mt-8 justify-center">
          <h5 className="text-white text-3xl text-center ">
            <span className="font-semibold">Konsul</span>me
          </h5>
        </div>
        <div className="sidebar-menu mt-10">
          <div className="text-white">
            <SidebarLink page={"/"} title="Dashboard" />
          </div>
          <div className="text-white">
            <h3
              className={`font-semibold mt-[17px] mb-[8px] mb ml-4 text-md text-link-sidebar ${
                !menuPermission.company &&
                !menuPermission.members &&
                !menuPermission.coach
                  ? "hidden"
                  : ""
              }`}
            >
              Manage Data
            </h3>
            {menuPermission.company && (
              <SidebarLink page={"/company"} title="Company" />
            )}
            {menuPermission.members && (
              <SidebarLink page={"/member"} title="Members" />
            )}
            {menuPermission.coach && (
              <SidebarLink page={"/coach"} title="Coach" />
            )}
          </div>
          <div className="text-white">
            <h3
              className={`font-semibold mt-[17px] mb-[8px] mb ml-4 text-md text-link-sidebar ${
                !menuPermission.activityData && !menuPermission.reportActivity
                  ? "hidden"
                  : ""
              }`}
            >
              Activity
            </h3>
            {menuPermission.activityData && (
              <SidebarLink page={"/activity"} title="Activity Data" />
            )}
            {menuPermission.reportActivity && (
              <SidebarLink page={"/report-activity"} title="Report Activity" />
            )}
          </div>
          <div className="text-white">
            <h3
              className={`font-semibold mt-[17px] mb-[8px] mb ml-4 text-md text-link-sidebar ${
                !menuPermission.article && !menuPermission.cateringHealty
                  ? "hidden"
                  : ""
              }`}
            >
              Content
            </h3>
            {menuPermission.article && (
              <SidebarLink page={"/article"} title="Articles" />
            )}
            {menuPermission.cateringHealty && (
              <SidebarLink page={"/catering"} title="Catering Healthy" />
            )}
          </div>
          <div className="text-white">
            <h3
              className={`font-semibold mt-[17px] mb-[8px] mb ml-4 text-md text-link-sidebar ${
                !menuPermission.membersCategory &&
                !menuPermission.topic &&
                !menuPermission.userManagement
                  ? "hidden"
                  : ""
              }`}
            >
              Settings
            </h3>

            {menuPermission.membersCategory && (
              <SidebarLink page={"/member-category"} title="Members Category" />
            )}
            {menuPermission.topic && (
              <SidebarLink page={"/topic"} title="Topic" />
            )}
            {menuPermission.userManagement && (
              <SidebarLink page={"/user-management"} title="Users Management" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
