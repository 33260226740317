import React, { useState } from "react";
import { DataTables, Loading } from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import { GetData } from "../../../config/service/ApiURL";
import { useEffect } from "react";

const Topic = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();
  const dataTopic = GetData("topic");

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => <p className="text-table">{row.name}</p>,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      cell: (row) => <p className="text-table">{row.type}</p>,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex flex-row items-center gap-2 ml-2">
          <Link
            to={`/topic/edit/${row.id}`}
            className="bg-secondary text-white text-md rounded-lg py-1 px-2"
          >
            Edit
          </Link>
          <button
            onClick={() => handleDeleteConfirm(row.id)}
            className="bg-danger text-white text-md rounded-lg py-1 px-2"
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const filteredItems = dataTopic.filter((item) => {
    const { name, type } = item;
    if (
      (name && name.toLowerCase().includes(filterText.toLowerCase())) ||
      (type && type.toLowerCase().includes(filterText.toLowerCase()))
    ) {
      return item;
    }
  });

  const handleDeleteConfirm = (id) => {
    let res = window.confirm("Sudah Yakin Ingin Dihapus");
    if (res) navigate(`/topic/delete/${id}`);
  };

  const load = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  useEffect(() => {
    if (dataTopic) load();
  }, []);

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <div className="flex justify-between">
            <h4 className="text-primary font-semibold text-lg">Topic</h4>
            <div className="flex flex-col items-end">
              <Link type="submit" to="add" className="button bg-success">
                Add Topic
              </Link>
              <input
                type="text"
                className="border mb-3 rounded-lg mt-4 px-4 py-2 w-96 focus:outline-none"
                onChange={(e) => setFilterText(e.target.value)}
                placeholder="Search"
              />
            </div>
          </div>
          <DataTables columns={columns} data={filteredItems} />
        </div>
      )}
    </div>
  );
};

export default Topic;
