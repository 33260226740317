import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Loading } from "../../../components";
import { DeleteData } from "../../../config/service/ApiURL";

const DeleteUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const deleteUser = DeleteData(`user/${params.userId}`);

  useEffect(() => {
    if (deleteUser) {
      setIsLoading(true);
      navigate("/user-management");
    }
  }, []);

  return <div>{isLoading && <Loading />}</div>;
};

export default DeleteUser;
