import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataTables, Loading } from "../../../components";
import { GetData } from "../../../config/service/ApiURL";

const Company = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();
  const dataCompany = GetData("company");

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <Link to={`/company/detail/${row.id}`} className="text-primary ml-2  ">
          {row.name}
        </Link>
      ),
    },
    {
      name: "Pic",
      selector: (row) => row.pic,
      sortable: true,
      cell: (row) => <p className="text-table">{row.pic}</p>,
    },
    {
      name: "Mail Domain",
      selector: (row) => row.mail_domain,
      sortable: true,
      cell: (row) => <p className="text-table">{row.mail_domain}</p>,
    },
    {
      name: "Expired",
      selector: (row) => row.expired_date,
      sortable: true,
      cell: (row) => <p className="text-table">{row.expired_date}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => <p className="text-table">{row.status}</p>,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex flex-row items-center gap-2 ml-2">
          <Link
            to={`/company/edit/${row.id}`}
            className="bg-secondary text-white text-md rounded-lg py-1 px-2"
          >
            Edit
          </Link>

          <button
            onClick={() => handleCondfirmDelete(row.id)}
            className="bg-danger text-white text-md rounded-lg py-1 px-2"
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const load = () => {
    if (dataCompany == []) setIsLoading(true);
    if (dataCompany != []) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  };

  const handleCondfirmDelete = (id) => {
    const res = window.confirm("Sudah Yakin Ingin Dihapus?");
    if (res) {
      navigate(`/company/delete/${id}`);
      setIsLoading(true);
    }
  };

  const filteredItems = dataCompany.filter((item) => {
    const { name, pic, mail_domain, status } = item;

    if (
      (name && name.toLowerCase().includes(filterText.toLowerCase())) ||
      (pic && pic.toLowerCase().includes(filterText.toLowerCase())) ||
      (mail_domain &&
        mail_domain.toLowerCase().includes(filterText.toLowerCase())) ||
      (status && status.toLowerCase().includes(filterText.toLowerCase()))
    ) {
      return item;
    }
  });

  useEffect(() => {
    load();
  }, []);

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <div className="flex justify-between">
            <h4 className="text-primary font-semibold text-lg">Company</h4>
            <div className="flex flex-col items-end">
              <Link type="submit" to="add" className="button bg-success">
                Add Company
              </Link>
              <input
                type="text"
                className="border mb-3 rounded-lg mt-4 px-4 py-2 w-96 focus:outline-none"
                onChange={(e) => setFilterText(e.target.value)}
                placeholder="Search"
              />
            </div>
          </div>
          <DataTables columns={columns} data={filteredItems} />
        </div>
      )}
    </div>
  );
};

export default Company;
