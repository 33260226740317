import React from "react";
import { FecthLogout } from "../../config/service/fecth";

const Navbar = () => {
  const email = localStorage.getItem("emailIsLogin");
  const handleLogout = async () => {
    const res = await FecthLogout();
    if (res) {
      localStorage.removeItem("key");
      window.location.href = "/";
    }
  };

  return (
    <div className="w-full bg-slate-100 fixed left-0 top-0 h-16 flex items-center justify-end p-8 z-20">
      <div className="flex items-center gap-4 text-white ">
        <h2 className="text-black font-semibold text-sm">{email}</h2>
        <button
          className="p-2 px-10 rounded-lg text-center bg-red-500"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Navbar;
