import { FaRegBuilding, FaTrash } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { FecthById, FecthGet } from "../../../../config/service/fecth";
import { DataTables } from "../../../../components";
import { IoArrowBackOutline } from "react-icons/io5";
import { APIBASEURL } from "../../../../config/service/ApiURL";

const Company = () => {
  let data = [];

  const params = useParams();
  const URLCOMPANY = `${APIBASEURL}/coach/${params.coachId}/company`;
  const URLCOACH = `${APIBASEURL}/coach/${params.coachId}`;
  const getCompany = FecthGet(URLCOMPANY);
  const getCoach = FecthById(URLCOACH);

  const customStyles = {
    rows: {
      style: {
        minHeight: "100px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "0px", // override the cell padding for head cells
      },
    },
    cells: {
      style: {
        width: "5rem",
        paddingLeft: "0px", // override the cell padding for data cells
        marginRight: "px",
      },
    },
  };

  const columns = [
    {
      name: "Coach Name",
      selector: (row) => row.coach,
      sortable: true,
      cell: (row) => <p>{row.coachName}</p>,
    },
    {
      name: "Coach Type",
      selector: (row) => row.coachType,
      sortable: true,
      cell: (row) => <p>{row.typeCoach}</p>,
    },
    {
      name: "Coach Email",
      selector: (row) => row.coachEmail,
      sortable: true,
      cell: (row) => <p>{row.emailCoach}</p>,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => <p>{row.name}</p>,
    },
    {
      name: "Pic",
      selector: (row) => row.pic,
      sortable: true,
      cell: (row) => <p>{row.pic}</p>,
    },
    {
      name: "Mail Domain",
      selector: (row) => row.mail_domain,
      sortable: true,
      cell: (row) => <p>{row.mail_domain}</p>,
    },
    {
      name: "Booked Activities",
      selector: (row) => row.booked_activities_count,
      sortable: true,
      cell: (row) => <p>{row.booked_activities}</p>,
    },
    {
      name: "Members",
      selector: (row) => row.members_count,
      sortable: true,
      cell: (row) => <p>{row.members}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => <p>{row.status}</p>,
    },

    {
      name: "Action",
      cell: (row) => (
        <div className="flex flex-row items-center gap-2">
          <Link
            to={`/coach/${params.coachId}/company/${row.companyId}`}
            className="bg-secondary text-white text-xl rounded-lg py-2 px-2"
          >
            <FaTrash />
          </Link>
        </div>
      ),
    },
  ];

  if (getCompany != undefined) {
    getCompany.map((item) => {
      const newCompany = {
        coachName: getCoach.name,
        typeCoach: getCoach.type,
        emailCoach: getCoach.email,
        companyId: item.company.id,
        name: item.company.name,
        pic: item.company.pic,
        mail_domain: item.company.mail_domain,
        booked_activities: item.company.booked_activities_count,
        members: item.company.members_count,
        status: item.company.status,
      };

      data.push(newCompany);
    });
  }

  return (
    <div>
      <div className="flex gap-4">
        <Link type="submit" to={`/coach`} className="button-orange">
          <IoArrowBackOutline className="text-3xl" />
          Back
        </Link>
        <Link type="submit" to="add" className="button-orange">
          <FaRegBuilding className="text-3xl" />
          Add Company
        </Link>
      </div>
      <DataTables columns={columns} data={data} customStyles={customStyles} />
    </div>
  );
};

export default Company;
