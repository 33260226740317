import { useEffect, useState } from "react";

const APIBASEURL = "https://api.konsulme.nstek.dev/user";
const requestSetting = (type, body = {}) => {
  const token = localStorage.getItem("key");

  const setting = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  switch (type) {
    case "GET":
      setting.method = "GET";
      break;
    case "POST":
      setting.method = "POST";
      setting.body = JSON.stringify(body);
      break;
    case "PUT":
      setting.method = "PUT";
      setting.body = JSON.stringify(body);
      break;
    case "DELETE":
      setting.method = "DELETE";
      setting.body = JSON.stringify(body);
      break;
    default:
      break;
  }

  return setting;
};

const GetData = (url) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getApi() {
      try {
        const req = await fetch(`${APIBASEURL}/${url}`, requestSetting("GET"));
        const res = await req.json();
        const data = res.data;

        setData(data);
      } catch (err) {
        console.log(err);
      }
    }

    getApi();
  }, [url]);

  return data;
};

const GetDataById = (url) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getApi() {
      try {
        const req = await fetch(`${APIBASEURL}/${url}`, requestSetting("GET"));
        const res = await req.json();
        const data = res;

        setData(data);
      } catch (err) {
        console.log(err);
      }
    }

    getApi();
  }, [url]);

  return data;
};

const UpdateData = (url, dataPost) => {
  async function postApi() {
    let dataRes = null;
    try {
      const req = await fetch(
        `${APIBASEURL}/${url}`,
        requestSetting("PUT", dataPost)
      );
      const res = await req.json();
      const data = res;

      dataRes = data;
    } catch (err) {
      console.log(err);
    }

    return dataRes;
  }

  return postApi();
};

const PostData = (url, dataPost) => {
  async function postApi() {
    let dataRes = null;
    try {
      const req = await fetch(
        `${APIBASEURL}/${url}`,
        requestSetting("POST", dataPost)
      );
      const res = await req.json();
      const data = res;

      dataRes = data;
    } catch (err) {
      console.log(err);
    }

    return dataRes;
  }

  return postApi();
};

const DeleteData = (url) => {
  async function getApi() {
    let isSuccess = false;
    try {
      const req = await fetch(`${APIBASEURL}/${url}`, requestSetting("DELETE"));
      const res = await req.ok;
      isSuccess = res;
    } catch (err) {
      console.log(err);
    }

    return isSuccess;
  }

  return getApi();
};

export {
  GetData,
  PostData,
  UpdateData,
  DeleteData,
  GetDataById,
  APIBASEURL,
  requestSetting,
};
