import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APIBASEURL } from "../../../../config/service/ApiURL";
import { FecthGet, FecthPost } from "../../../../config/service/fecth";

const AddCoachCompanies = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const params = useParams();
  const URL = `${APIBASEURL}/coach`;
  const URLADDCACH = `${APIBASEURL}/company/${params.companyId}/coach`;
  const getCoach = FecthGet(URL);

  const handleAddCoachCompany = async (id) => {
    const dataPost = {
      coach_id: id,
    };

    const addCoach = await FecthPost(URLADDCACH, dataPost);
    if (addCoach)
      navigate(`/company/${params.companyId}/coach`, { replace: true });
  };

  useEffect(() => {
    setTimeout(() => {
      if (getCoach !== undefined || getCoach !== []) {
        setLoading(false);
      }

      if (!loading) {
        setData(getCoach);
      }
    }, 1000);
  }, [loading, data]);

  return (
    <div>
      {!loading && data != []
        ? data.map((coach) => (
            <div className="bg-white shadow-lg flex gap-5 my-5 p-5">
              {coach.name}
              <button
                className="bg-primary text-white font-semibold p-2 rounded-md"
                onClick={() => handleAddCoachCompany(coach.id)}
              >
                Add
              </button>
            </div>
          ))
        : "Loading..."}
    </div>
  );
};

export default AddCoachCompanies;
