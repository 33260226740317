import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FecthById } from "../../../config/service/fecth";
import { Loading } from "../../../components";
import { IoArrowBackOutline } from "react-icons/io5";
import { APIBASEURL } from "../../../config/service/ApiURL";

const DetailArticle = () => {
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();

  const URL = `${APIBASEURL}/article/${params.articleId}`;
  const getArticleById = FecthById(URL);

  const load = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  useEffect(() => {
    if (getArticleById) load();
  }, []);

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <Link
            type="submit"
            to={`/article`}
            className="button bg-secondary mb-6"
          >
            <IoArrowBackOutline className="text-3xl" />
            Back
          </Link>
          <img
            src={getArticleById.image}
            alt={getArticleById.title}
            className="md:w-96 w-full rounded-lg"
          />
          <h1 className="my-5 text-secondary font-semibold text-lg">
            {getArticleById.title}
          </h1>
          <p className="leading-relaxed mb-10">{getArticleById.short_desc}</p>
          <a href={getArticleById.url} className="text-primary ">
            {getArticleById.url}
          </a>
        </div>
      )}
    </div>
  );
};

export default DetailArticle;
