import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Loading } from "../../../components";
import { DeleteData } from "../../../config/service/ApiURL";

const DeleteMemberCatergory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const deleteMemberCategory = DeleteData(
    `member_category/${params.memberCategoryId}`
  );

  useEffect(() => {
    if (deleteMemberCategory) {
      setIsLoading(true);
      navigate("/member-category");
    }
  }, []);

  return <div>{isLoading && <Loading />}</div>;
};

export default DeleteMemberCatergory;
