import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HiUserAdd } from "react-icons/hi";
import { FaRegBuilding, FaTrash } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { DataTables, Loading } from "../../../components";
import { FecthGet } from "../../../config/service/fecth";
import { APIBASEURL } from "../../../config/service/ApiURL";

const Coach = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();
  const data = FecthGet(`${APIBASEURL}/coach`);

  const handleConfirmDelete = (id) => {
    setIsLoading(true);

    const res = window.confirm("Sudah Yakin Ingin Dihapus?");
    if (res) navigate(`/coach/delete/${id}`);
  };

  const columns = [
    {
      name: "Photo",
      selector: (row) => row.photo,
      sortable: true,
      cell: (row) => (
        <img
          className="w-16 rounded-md my-2 ml-2"
          alt={row.name}
          src={row.photo}
        />
      ),
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <Link
          className="text-table text-primary "
          to={`/coach/detail/${row.id}`}
        >
          {row.name}
        </Link>
      ),
    },

    {
      name: "Phone",
      selector: (row) => row.phone,
      cell: (row) => <p className="text-table">{row.phone}</p>,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => <p className="text-table">{row.email}</p>,
    },

    {
      name: "Type",
      selector: (row) => <p className="text-table">{row.type}</p>,
    },
    {
      name: "Status",
      selector: (row) => <p className="text-table ">{row.status}</p>,
    },
    {
      name: "Online",
      selector: (row) => (
        <p className="ml-5">{row.is_online === 1 ? "Yes" : "No"}</p>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex flex-row items-center gap-2">
          <Link
            to={`/coach/edit/${row.id}`}
            className="bg-secondary text-white text-md rounded-lg py-1 px-2"
          >
            Edit
          </Link>

          <button
            onClick={() => handleConfirmDelete(row.id)}
            className="bg-danger text-white text-md rounded-lg py-1 px-2"
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const filteredItems =
    data === undefined
      ? ""
      : data.filter((item) => {
          if (
            (item.name &&
              item.name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.email &&
              item.email.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.phone &&
              item.phone.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.location &&
              item.location.toLowerCase().includes(filterText.toLowerCase()))
          ) {
            return item;
          }
        });

  const load = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  useEffect(() => {
    if (data) {
      load();
    }
  }, [data]);

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <div className="flex justify-between">
            <h4 className="text-primary font-semibold text-lg">Coach</h4>
            <div className="flex flex-col items-end">
              <Link type="submit" to="add" className="button bg-success">
                Add Coach
              </Link>
              <input
                type="text"
                className="border mb-3 rounded-lg mt-4 px-4 py-2 w-96 focus:outline-none"
                onChange={(e) => setFilterText(e.target.value)}
                placeholder="Search"
              />
            </div>
          </div>
          <DataTables columns={columns} data={filteredItems} />
        </div>
      )}
    </>
  );
};

export default Coach;
