import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../components";
import { APIBASEURL } from "../../../config/service/ApiURL";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const key = localStorage.getItem("key");
  const navigate = useNavigate();

  const loginRequest = async (user) => {
    setIsLoading(true);
    const URL = `${APIBASEURL}/auth/login`;

    const myHeaders = {
      "Content-Type": "application/json",
    };

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(user),
    };

    const API = await fetch(URL, requestOptions);
    const res = await API.json();
    const data = res.access_token;

    return data;
  };

  const handleLoginRequest = async (e) => {
    e.preventDefault();
    const token = await loginRequest({ email, password });

    setTimeout(() => {
      if (token == undefined) alert("Password atau Email Salah");
      setIsLoading(false);
    }, 1500);

    if (token !== undefined) {
      localStorage.setItem("key", token);
      localStorage.setItem("emailIsLogin", email);
      setTimeout(() => {
        setIsLoading(false);
        navigate("/", { replace: true });
      }, 1500);
      return;
    }
  };

  if (key) {
    navigate("/", { replace: true });
    return;
  }

  return (
    <form
      onSubmit={(e) => handleLoginRequest(e)}
      className="mx-auto md:w-[30rem] w-full h-max  p-10  text-white "
    >
      {isLoading && <Loading />}
      <div className="text-center mb-16">
        <h1 className="text-4xl mb-1 text-secondary">
          <span className="font-semibold text-primary">Konsul</span>me
        </h1>
      </div>
      <div className="flex flex-col gap-2 mt-8">
        <label htmlFor="email" className="text-md font-bold text-semi-black ">
          Email (username)
        </label>
        <input
          type="email"
          id="email"
          className="rounded-lg py-2 px-4 border text-slate-800 focus:outline-none"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="@example.com"
        />
      </div>
      <div className="flex flex-col gap-2 mt-6">
        <label htmlFor="password" className="text-md font-bold text-semi-black">
          Password :
        </label>
        <input
          type="password"
          id="password"
          className="rounded-lg py-2 px-4 border text-slate-800 focus:outline-none"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="*****"
        />
      </div>
      <button
        type="submit"
        className="bg-primary text-center py-2 w-full text-white rounded-md mt-10 ease-out duration-300 hover:shadow-lg hover:bg-secondary hover:text-white "
      >
        Login
      </button>
    </form>
  );
};

export default Login;
