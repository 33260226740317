import { useState, useEffect } from "react";
import { DataTables, Loading } from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import { GetData } from "../../../config/service/ApiURL";

const User = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();
  const dataUser = GetData("user");

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => <p className="text-table">{row.name}</p>,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      cell: (row) => <p className="text-table">{row.email}</p>,
    },
    {
      name: "Menu Permission",
      selector: (row) => row.menu_permission,
      sortable: true,
      cell: (row) => <p className="text-table py-3">{row.menu_permission}</p>,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex flex-row items-center gap-2 ml-2">
          <Link
            to={`/user-management/edit/${row.id}`}
            className="bg-secondary text-white text-md rounded-lg py-1 px-2"
          >
            Edit
          </Link>
          <button
            onClick={() => handleDeleteConfirm(row.id)}
            className="bg-danger text-white text-md rounded-lg py-1 px-2"
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const filteredItems = dataUser.filter((item) => {
    const { name, email, menu_permission } = item;
    if (
      (name && name.toLowerCase().includes(filterText.toLowerCase())) ||
      (email && email.toLowerCase().includes(filterText.toLowerCase())) ||
      (menu_permission &&
        menu_permission.toLowerCase().includes(filterText.toLowerCase()))
    ) {
      return item;
    }
  });

  const load = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  const handleDeleteConfirm = (id) => {
    let res = window.confirm("Sudah Yakin Ingin Dihapus");
    if (res) navigate(`/user-management/delete/${id}`);
  };

  useEffect(() => {
    if (dataUser) load();

    if (sessionStorage.getItem("isReload")) {
      sessionStorage.removeItem("isReload");
      window.location.reload();
    }
  }, [dataUser]);

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <div className="flex justify-between">
            <h4 className="text-primary font-semibold text-lg">
              User Management
            </h4>
            <div className="flex flex-col items-end">
              <Link type="submit" to="add" className="button bg-success">
                Add User
              </Link>
              <input
                type="text"
                className="border mb-3 rounded-lg mt-4 px-4 py-2 w-96 focus:outline-none"
                onChange={(e) => setFilterText(e.target.value)}
                placeholder="Search"
              />
            </div>
          </div>
          <DataTables columns={columns} data={filteredItems} />
        </div>
      )}
    </div>
  );
};

export default User;
