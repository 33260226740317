import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../components";
import { GetData, GetDataById, UpdateData } from "../../../config/service/ApiURL";
import {
  FecthById,
  FecthPostAsset,
  FecthUpdate,
} from "../../../config/service/fecth";

const EditMember = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState();
  const [imageDirectory, setImageDirectory] = useState("");
  const [preview, setPreview] = useState("");
  const [FormData, setFormData] = useState({
    password: "",
    member_category_id: "",
    // name: "",
    // gender: "",
    // height: "",
    // weight: "",
    // email: "",
    // phone: "",
    // status: "Active",
    // phone: "",
  });

  const params = useParams();
  const navigate = useNavigate();
  const dataMember = GetDataById(`member/${params.memberId}`);
  const dataCategory = GetData(`member_category`);
  const dataMemberCategory = GetDataById(`member_category/${dataMember.member_category_id}`);

  const handleChangeImage = (e) => {
    setSelectedImage(e.target.files[0]);
    setImageDirectory(e.target.value);
  };

  const handleEditMember = async (e) => {
    e.preventDefault();
    // setIsLoading(true);

    // const image = await FecthPostAsset(selectedImage, imageDirectory);
    // if (selectedImage) FormData.photo = image;
    if(FormData.password === "") delete FormData.password;
    console.log(dataMemberCategory)
    const response = await UpdateData(`member/${params.memberId}`, FormData);
    if (response) return navigate("/member", { replace: true });
  };

  const load = () => {
    if(dataMemberCategory != undefined && dataMemberCategory != "") {
      setIsLoading(false);
      console.log("Ada selogf")
      return;
    }
  };

  useEffect(() => {
    load();
    if (selectedImage !== undefined) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }

    // setFormData({
    //   name: dataMember.name,
    //   gender: dataMember.gender,
    //   height: dataMember.height,
    //   weight: dataMember.weight,
    //   email: dataMember.email,
    //   phone: dataMember.phone,
    //   status: dataMember.status === null ? "Active" : dataMember.status,
    //   phone: dataMember.phone,
    // });
  }, [dataMember, selectedImage, preview]);

  useEffect(() => {
    setFormData({member_category_id: dataMemberCategory.id});
  }, [dataMember, dataMemberCategory]);

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <h4 className="text-primary font-semibold text-lg">Members</h4>
          <form
            onSubmit={(e) => handleEditMember(e)}
            className="lg:w-[50rem] w-full mt-[45px]"
          >
            <div className="grid md:grid-cols-2 grid-cols-1 gap-5 ">
              <div className="input-form-box">
                <label htmlFor="category">Category : </label>
                <select name="category" id="category" 
                  value={FormData.member_category_id || ''}
                  onChange={(e) =>
                    setFormData({ ...FormData, member_category_id: e.target.value })
                  }>
                    {dataCategory.map(({id, name}) => ( 
                        <option value={id} key={id}>{name}</option>
                    ))}
                </select>
              </div>
              <div className="input-form-box">
                <label htmlFor="password">Password : </label>
                <input
                  type="text"
                  id="password"
                  value={FormData.password || ""}
                  onChange={(e) =>
                    setFormData({ ...FormData, password: e.target.value })
                  }
                />
                <label htmlFor="passowrd">
                  (Kosongkan jika tidak mau diubah)
                </label>
              </div>
              {/* <div className="input-form-box">
            <label htmlFor="name">Name : </label>
            <input
              type="text"
              id="name"
              value={FormData.name || ""}
              onChange={(e) =>
                setFormData({ ...FormData, name: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="age">Age : </label>
            <input
              type="number"
              id="age"
              value={FormData.age || ""}
              onChange={(e) =>
                setFormData({ ...FormData, age: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="gender">Gender :</label>
            <select
              name="gender"
              id="gender"
              value={FormData.gender || ""}
              onChange={(e) =>
                setFormData({ ...FormData, gender: e.target.value })
              }
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div className="input-form-box">
            <label htmlFor="height">Height : </label>
            <input
              type="number"
              id="height"
              value={FormData.height || ""}
              onChange={(e) =>
                setFormData({ ...FormData, height: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="weigth">Weigth : </label>
            <input
              type="number"
              id="weigth"
              value={FormData.weight || ""}
              onChange={(e) =>
                setFormData({ ...FormData, weight: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="email">Email : </label>
            <input
              type="email"
              id="email"
              value={FormData.email || ""}
              onChange={(e) =>
                setFormData({ ...FormData, email: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="phone">Phone : </label>
            <input
              type="number"
              id="phone"
              value={FormData.phone || ""}
              onChange={(e) =>
                setFormData({ ...FormData, phone: e.target.value })
              }
            />
          </div>

          <div className="input-form-box">
            <label htmlFor="status">Status :</label>
            <select
              name="status"
              id="status"
              value={FormData.status || ""}
              onChange={(e) =>
                setFormData({ ...FormData, status: e.target.value })
              }
            >
              <option value="Active">Active</option>
              <option value="Non Active">Non Active</option>
            </select>
          </div>
          <div className="input-form-box">
            <label htmlFor="photo">Photo : </label>
            <input
              type="file"
              accept="image/*"
              id="photo"
              onChange={handleChangeImage}
            />
          </div> */}
            </div>
          </form>
          <div className="flex item-center gap-2 -mt-3">
            <Link type="submit" to="/member" className="button bg-danger">
              Cancel
            </Link>
            <button
              type="submit"
              className="button bg-success"
              onClick={handleEditMember}
            >
              Save Member
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditMember;
