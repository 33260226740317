import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../components";
import { GetDataById, UpdateData } from "../../../config/service/ApiURL";

const EditMemberCategory = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [FormData, setFormData] = useState({
    name: "",
  });
  const params = useParams();
  const navigate = useNavigate();
  const dataMemberCategory = GetDataById(
    `member_category/${params.memberCategoryId}`
  );

  const load = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  const handleSaveMemberCategory = async () => {
    if (FormData.name === "") return;

    setIsLoading(true);
    const res = await UpdateData(
      `member_category/${params.memberCategoryId}`,
      FormData
    );
    if (res) navigate("/member-category");
  };

  useEffect(() => {
    if (dataMemberCategory) load();

    setFormData({
      name: dataMemberCategory.name,
    });
  }, [dataMemberCategory]);

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <h4 className="text-primary font-semibold text-lg">
            Member Category
          </h4>
          <form
            className="lg:w-[50rem] w-full mt-[45px]"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
              <div className="input-form-box">
                <label htmlFor="name">Name : </label>
                <input
                  type="text"
                  id="name"
                  placeholder="name"
                  required
                  value={FormData.name || ""}
                  onChange={(e) =>
                    setFormData({ ...FormData, name: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="flex item-center gap-2 -mt-[10px] ">
              <Link
                type="submit"
                to="/member-category"
                className="button bg-danger"
              >
                Cancel
              </Link>
              <button
                type="submit"
                className="button bg-success"
                onClick={handleSaveMemberCategory}
              >
                Save Member Category
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditMemberCategory;
