import MainApp from "./MainApp/MainApp";
import { BrowserRouter as Router, HashRouter } from "react-router-dom";

function App() {
  return (
    <HashRouter>
      <MainApp />
    </HashRouter>
  );
}

export default App;
