import React, { useState } from "react";
import { useEffect } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import { Loading } from "../../../components";
import {
  APIBASEURL,
  GetDataById,
  requestSetting,
} from "../../../config/service/ApiURL";

const MemberDetail = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [categoryMember, setCategoryMember] = useState("");
  const params = useParams();
  const dataMember = GetDataById(`member/${params.memberId}`);

  const load = () => {
    setTimeout(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!dataMember) return;
    if (dataMember) load();
    async function dataCategoryMember() {
      const api = await fetch(
        `${APIBASEURL}/member_category/${dataMember.member_category_id}`,
        requestSetting("GET")
      );
      const res = await api.json();
      const data = res.name;

      setCategoryMember(data);
    }

    dataCategoryMember();
    console.log(dataMember);
  }, [dataMember]);
  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <Link
            type="submit"
            to={`/member`}
            className="button bg-secondary mb-6"
          >
            <IoArrowBackOutline className="text-3xl" />
            Back
          </Link>
          <h4 className="text-primary font-semibold text-lg">Member</h4>
          <div className="w-full mt-5">
            <img
              src={dataMember.photo}
              alt={"Photo : " + dataMember.name}
              className="rounded-xl w-52 mb-8"
            />
            <ul className="flex flex-col gap-1 w-[20rem]">
              <li className="company-list-detail">
                <span>Name</span>
                {dataMember.name}
              </li>
              <li className="company-list-detail">
                <span>Gender</span>
                {dataMember.gender}
              </li>
              <li className="company-list-detail">
                <span>Height</span>
                {dataMember.height}
              </li>
              <li className="company-list-detail">
                <span>Weight</span>
                {dataMember.weight}
              </li>
              <li className="company-list-detail">
                <span>Category</span>
                {categoryMember}
              </li>
              <li className="company-list-detail">
                <span>Phone </span>
                {dataMember.phone}
              </li>
              <li className="company-list-detail">
                <span>Email </span>
                {dataMember.email}
              </li>
              <li className="company-list-detail">
                <span> Status </span>
                {dataMember.status}
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default MemberDetail;
