import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  AddArticle,
  AddCatering,
  AddCoach,
  AddCoachCompanies,
  AddCoachCompany,
  AddCompany,
  AddMember,
  Article,
  Coach,
  CoachCompanies,
  CoachCompany,
  Company,
  DeleteArticle,
  DeleteCoach,
  DeleteCoachCompanies,
  DeleteCoachCompany,
  DeleteCompany,
  DeleteMember,
  DetailArticle,
  DetailCoach,
  DetailCompany,
  EditArticle,
  EditCoach,
  EditCompany,
  EditMember,
  Login,
  Member,
  MemberDetail,
  Catering,
  DeleteCatering,
  DetailCatering,
  EditCatering,
  Activity,
  ReportActivity,
  Dashboard,
  MemberCategory,
  AddMemberCategory,
  EditMemberCategory,
  DetailMemberCategory,
  DeleteMemberCategory,
  Topic,
  AddTopic,
  EditTopic,
  DetailTopic,
  DeleteTopic,
  User,
  AddUser,
  EditUser,
  DetailUser,
  DeleteUser,
  EditCategory,
} from "../../pages";

const Routers = () => {
  return (
    <Routes>
      {/* Login */}
      <Route path="/login" element={<Login />} />

      {/* Dashboard */}
      <Route path="/" element={<Dashboard />} />

      {/* Coach */}
      <Route path="/coach" element={<Coach />} />
      <Route path="/coach/add" element={<AddCoach />} />
      <Route path="/coach/edit/:coachId" element={<EditCoach />} />
      <Route path="/coach/detail/:coachId" element={<DetailCoach />} />
      <Route path="/coach/delete/:coachId" element={<DeleteCoach />} />

      {/* Coach Companies */}
      <Route path="/coach/:coachId/company" element={<CoachCompanies />} />
      <Route
        path="/coach/:coachId/company/add"
        element={<AddCoachCompanies />}
      />
      <Route
        path="/coach/:coachId/company/:companyId"
        element={<DeleteCoachCompanies />}
      />
      {/* Companies */}
      <Route path="/company" element={<Company />} />
      <Route path="/company/add" element={<AddCompany />} />
      <Route path="/company/edit/:companyId" element={<EditCompany />} />
      <Route path="/company/delete/:companyId" element={<DeleteCompany />} />
      <Route path="/company/detail/:companyId" element={<DetailCompany />} />

      {/* Companies Coach */}
      <Route path="/company/:companyId/coach" element={<CoachCompany />} />
      <Route
        path="/company/:companyId/coach/add"
        element={<AddCoachCompany />}
      />
      <Route
        path="/company/:companyId/coach/:coachId"
        element={<DeleteCoachCompany />}
      />

      {/* Member */}
      <Route path="/member" element={<Member />} />
      <Route path="/member/add" element={<AddMember />} />
      <Route path="/member/detail/:memberId" element={<MemberDetail />} />
      <Route path="/member/edit/:memberId" element={<EditMember />} />
      <Route path="/member/delete/:memberId" element={<DeleteMember />} />
      <Route path="/member/editCategory/:memberId" element={<EditCategory />} />

      {/* Article */}
      <Route path="/article" element={<Article />} />
      <Route path="/article/add" element={<AddArticle />} />
      <Route path="/article/edit/:articleId" element={<EditArticle />} />
      <Route path="/article/detail/:articleId" element={<DetailArticle />} />
      <Route path="/article/delete/:articleId" element={<DeleteArticle />} />

      {/* Catering */}
      <Route path="/catering" element={<Catering />} />
      <Route path="/catering/add" element={<AddCatering />} />
      <Route path="/catering/edit/:cateringId" element={<EditCatering />} />
      <Route path="/catering/detail/:cateringId" element={<DetailCatering />} />
      <Route path="/catering/delete/:cateringId" element={<DeleteCatering />} />

      {/* Member Category */}
      <Route path="/member-category" element={<MemberCategory />} />
      <Route path="/member-category/add" element={<AddMemberCategory />} />
      <Route
        path="/member-category/edit/:memberCategoryId"
        element={<EditMemberCategory />}
      />
      <Route
        path="/member-category/detail/:memberCategoryId"
        element={<DetailMemberCategory />}
      />
      <Route
        path="/member-category/delete/:memberCategoryId"
        element={<DeleteMemberCategory />}
      />

      {/* Topic */}
      <Route path="/topic" element={<Topic />} />
      <Route path="/topic/add" element={<AddTopic />} />
      <Route path="/topic/edit/:topicId" element={<EditTopic />} />
      <Route path="/topic/detail/:topicId" element={<DetailTopic />} />
      <Route path="/topic/delete/:topicId" element={<DeleteTopic />} />

      {/* User */}
      <Route path="/user-management" element={<User />} />
      <Route path="/user-management/add" element={<AddUser />} />
      <Route path="/user-management/edit/:userId" element={<EditUser />} />
      <Route path="/user-management/detail/:userId" element={<DetailUser />} />
      <Route path="/user-management/delete/:userId" element={<DeleteUser />} />

      {/* Activity */}
      <Route path="/activity" element={<Activity />} />
      <Route path="/report-activity" element={<ReportActivity />} />
    </Routes>
  );
};

export default Routers;
