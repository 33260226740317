import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Loading, TagsInput } from "../../../components";
import { APIBASEURL } from "../../../config/service/ApiURL";
import {
  FecthById,
  FecthPost,
  FecthPostAsset,
  FecthUpdate,
} from "../../../config/service/fecth";

const EditCatering = () => {
  const [benefitDeleted, setBenefitDeleted] = useState([]);
  const [benefit, setBenefit] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState();
  const [imageDirectory, setImageDirectory] = useState("");
  const [preview, setPreview] = useState("");
  const [photoOld, setPhotoOld] = useState(false);
  const [FormData, setFormData] = useState({
    name: "",
    description: "",
    image: "",
    benefit: [],
    benefit_deleted: [],
  });
  const navigate = useNavigate();
  const params = useParams();
  const URL = `${APIBASEURL}/catering/${params.cateringId}`;
  const getDataCatering = FecthById(URL);

  const handleChangeImage = (e) => {
    setSelectedImage(e.target.files[0]);
    setImageDirectory(e.target.value);
  };

  const handleEditCatering = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const benefitIdDeleted = [];

    getDataCatering.benefit.forEach((benefitOld) => {
      benefitDeleted.forEach((benefitDelete) => {
        if (benefitOld.benefit == benefitDelete) {
          benefitIdDeleted.push(benefitOld.id);
        }
      });
    });

    FormData.benefit_deleted = benefitIdDeleted;
    FormData.benefit = benefit;

    const image = await FecthPostAsset(selectedImage, imageDirectory);
    if (photoOld) FormData.image = getDataCatering.image;
    if (selectedImage) FormData.image = image;

    const res = await FecthUpdate(URL, FormData);
    console.log(res);
    if (res) return navigate("/catering", { replace: true });
  };

  const handleSaveCatering = async (e) => {
    e.preventDefault();

    const image = await FecthPostAsset(selectedImage, imageDirectory);
    if (photoOld) FormData.image = getDataCatering.image;
    if (selectedImage) FormData.image = image;

    const response = await FecthUpdate(URL, FormData);
    if (response) return navigate("/catering", { replace: true });
  };

  const load = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    setFormData({
      name: getDataCatering.name,
      description: getDataCatering.description,
      benefit: getDataCatering.benefit,
    });
  };

  useEffect(() => {
    if (getDataCatering) load();

    if (getDataCatering.image !== "" || getDataCatering.image !== undefined)
      setPhotoOld(true);
    if (photoOld) setPreview(getDataCatering.image);

    if (selectedImage !== undefined) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  }, [selectedImage, preview, getDataCatering]);

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <h4 className="text-primary mb-10 font-semibold text-lg">
            Catering - Edit
          </h4>

          <div className="preview-img">
            <img src={preview} />
          </div>
          <form
            onSubmit={(e) => handleEditCatering(e)}
            className=" lg:w-[50rem] w-full -mt-[20px]"
          >
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
              <div className="input-form-box">
                <label htmlFor="name">Name : </label>
                <input
                  type="text"
                  id="name"
                  value={FormData.name || ""}
                  placeholder="name"
                  onChange={(e) =>
                    setFormData({ ...FormData, name: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="image">Image : </label>
                <input
                  type="file"
                  accept="image/*"
                  id="image"
                  onChange={handleChangeImage}
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="description">Description : </label>
                <textarea
                  name="description"
                  id="description"
                  value={FormData.description || ""}
                  placeholder="description"
                  onChange={(e) =>
                    setFormData({ ...FormData, description: e.target.value })
                  }
                ></textarea>
              </div>
              <TagsInput
                benefitDeleted={benefitDeleted}
                setBenefit={setBenefit}
                defaults={FormData.benefit}
                setBenefitDeleted={setBenefitDeleted}
              />
            </div>
            <div className="flex items-center gap-2">
              <Link type="submit" to="/catering" className="button bg-danger">
                Cancel
              </Link>
              <button
                className="button bg-success"
                type="submit"
                onClick={handleEditCatering}
              >
                Save Catering
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditCatering;
