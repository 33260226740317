import { Link } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";

const SidebarLink = ({ page, title }) => {
  return (
    <div className="flex flex-col  border-b border-black border-opacity-10">
      <Link
        to={page}
        className="flex items-center text-link-sidebar justify-between mt-[10px] pb-2 mx-4"
      >
        {title}
        <MdOutlineArrowForwardIos className="font-bold text-xl" />
      </Link>
    </div>
  );
};

export default SidebarLink;
