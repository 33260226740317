import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Loading } from "../../../components";
import { PostData } from "../../../config/service/ApiURL";

const AddTopic = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [FormData, setFormData] = useState({
    name: "",
    type: "Psikolog",
  });
  const navigate = useNavigate();

  const handleSaveTopic = async () => {
    if (FormData.name === "") return;

    setIsLoading(true);
    const res = await PostData("topic", FormData);
    if (res) navigate("/topic");
  };

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <h4 className="text-primary font-semibold text-lg">Topic</h4>
          <form
            className="lg:w-[50rem] w-full mt-[45px]"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
              <div className="input-form-box">
                <label htmlFor="name">Name : </label>
                <input
                  type="text"
                  id="name"
                  placeholder="name"
                  required
                  onChange={(e) =>
                    setFormData({ ...FormData, name: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="type">Type : </label>
                <select
                  name="type"
                  id="type"
                  required
                  placeholder="type"
                  onChange={(e) =>
                    setFormData({ ...FormData, type: e.target.value })
                  }
                >
                  <option value="Psikolog">Psikolog</option>
                  <option value="Fitness">Fitness</option>
                  <option value="Dokter">Dokter</option>
                  <option value="Dietitian">Dietitian</option>
                </select>
              </div>
            </div>
            <div className="flex item-center gap-2 -mt-[10px] ">
              <Link type="submit" to="/topic" className="button bg-danger">
                Cancel
              </Link>
              <button
                type="submit"
                className="button bg-success"
                onClick={handleSaveTopic}
              >
                Save Topic
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddTopic;
