import React from "react";
import { FaTrash } from "react-icons/fa";
import { HiUserAdd } from "react-icons/hi";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import { DataTables } from "../../../../components";
import { APIBASEURL } from "../../../../config/service/ApiURL";
import { FecthGet } from "../../../../config/service/fecth";

const CoachCompanies = () => {
  let data = [];
  const params = useParams();
  const URL = `${APIBASEURL}/company/${params.companyId}/coach`;
  const getCoach = FecthGet(URL);

  const customStyles = {
    rows: {
      style: {
        minHeight: "120px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "0px", // override the cell padding for head cells
      },
    },
    cells: {
      style: {
        width: "5rem",
        paddingLeft: "0px", // override the cell padding for data cells
        marginRight: "0px",
      },
    },
  };

  const columns = [
    {
      name: "Photo",
      selector: (row) => row.photo,
      sortable: true,
      cell: (row) => (
        <img src={row.photo} alt={row.name} className="w-16 rounded-md" />
      ),
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => <p>{row.name}</p>,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      cell: (row) => <p>{row.type}</p>,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      cell: (row) => <p>{row.email}</p>,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
      cell: (row) => <p>{row.phone}</p>,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
      cell: (row) => <p>{row.location}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => <p>{row.status}</p>,
    },

    {
      name: "Action",
      cell: (row) => (
        <div className="flex flex-row items-center gap-2">
          <Link
            to={`/company/${row.company_id}/coach/${row.coach_id}`}
            className="bg-secondary text-white text-xl rounded-lg py-2 px-2"
          >
            <FaTrash />
          </Link>
        </div>
      ),
    },
  ];

  if (getCoach !== undefined) {
    getCoach.forEach((coach) => {
      if (coach.coach === null) return;

      let newCoach = {
        coach_id: coach.coach_id,
        company_id: coach.company_id,
        name: coach.coach.name,
        location: coach.coach.location,
        phone: coach.coach.phone,
        email: coach.coach.email,
        education: coach.coach.education,
        type: coach.coach.type,
        photo: coach.coach.photo,
        status: coach.coach.status,
        is_online: coach.coach.is_online,
      };

      data.push(newCoach);
    });
  }

  return (
    <div>
      <div className="flex gap-4">
        <Link type="submit" to={`/company`} className="button-orange">
          <IoArrowBackOutline className="text-3xl" />
          Back
        </Link>
        <Link
          type="submit"
          to={`/company/${params.companyId}/coach/add`}
          className="button-orange"
        >
          <HiUserAdd className="text-3xl" />
          Add Coach
        </Link>
      </div>
      <DataTables columns={columns} data={data} customStyles={customStyles} />
    </div>
  );
};

export default CoachCompanies;
