import React, { useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

const TagsInput = ({
  setBenefit,
  defaults,
  setBenefitDeleted,
  benefitDeleted,
}) => {
  const [tags, setTags] = React.useState([]);

  const addTags = (event) => {
    if (event.keyCode === 16 && event.target.value !== "") {
      setTags([...tags, event.target.value]);
      setBenefit([...tags, event.target.value]);
      event.target.value = "";
    }
  };

  const removeTags = (index) => {
    const dataTagsNew = tags.filter((tag) => tag !== tags[index]);
    const dataTagsDeleted = tags.filter((tag) => tag === tags[index]);
    setBenefitDeleted([...benefitDeleted, ...dataTagsDeleted]);
    setTags(dataTagsNew);
    setBenefit(dataTagsNew);
  };

  useEffect(() => {
    if (defaults) {
      let newBenefit = [];

      defaults.forEach((def) => {
        newBenefit.push(def.benefit);
      });

      setTags([...tags, ...newBenefit]);
      setBenefit([...tags, ...newBenefit]);
    }
  }, []);

  return (
    <div className="">
      <label htmlFor="benefit" className="text-sm">
        Benefit :{" "}
      </label>
      <div className="flex items-start flex-wrap border border-bgrey rounded-lg p-2 mt-1">
        <ul className="flex flex-wrap gap-2">
          {tags.map((tag, index) => (
            <li
              key={index}
              className="flex h-8 w-auto font-reguler items-center justify-center px-3 bg-secondary rounded-xl  text-white mb-2"
            >
              <span>{tag}</span>
              <AiFillCloseCircle
                className="justify-self-end"
                onClick={() => removeTags(index)}
              />
            </li>
          ))}
        </ul>
        <input
          className="flex-1 border-0 py-2 pl-4 focus:outline-none  "
          type="text"
          placeholder="Press shift to add tags"
          onKeyUp={(event) => addTags(event)}
        />
      </div>
    </div>
  );
};

export default TagsInput;
