import React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APIBASEURL } from "../../../config/service/ApiURL";
import { FecthDelete } from "../../../config/service/fecth";

const DeleteCoach = () => {
  const navigate = useNavigate();
  const params = useParams();
  const URL = `${APIBASEURL}/coach/` + params.coachId;
  const dataCoach = FecthDelete(URL);

  useEffect(() => {
    if (dataCoach) {
      navigate("/coach", { replace: true });
      return;
    }
  });
};

export default DeleteCoach;
