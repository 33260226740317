import React, { useEffect, useState } from "react";
import { DataTables, Loading } from "../../../components";
import { APIBASEURL, GetData, requestSetting } from "../../../config/service/ApiURL";

const Activity = () => {
  const [data, setData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const dataActivity = GetData(`activity`);

  const columns = [
    {
      name: "Activity Number",
      selector: (row) => row.activity_number,
      sortable: true,
      cell: (row) => <p className="text-table">{row.activity_number}</p>,
    },
    {
      name: "Schedule",
      selector: (row) => row.schedule_datetime,
      sortable: true,
      cell: (row) => <p className="text-table">{row.schedule_datetime}</p>,
    },
    {
      name: "Member",
      selector: (row) => row.member_id,
      sortable: true,
      cell: (row) => <p className="text-table">{row.member_name}</p>,
    },
    {
      name: "Coach ",
      selector: (row) => row.coach_id,
      sortable: true,
      cell: (row) => <p className="text-table">{row.coach_name}</p>,
    },
    {
      name: "Type ",
      selector: (row) => row.type,
      sortable: true,
      cell: (row) => <p className="text-table">{row.type}</p>,
    },
    {
      name: "Status ",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => <p className="text-table">{row.status}</p>,
    },
    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <div className="flex flex-row items-center gap-2 ml-2">
    //       <Link
    //         to={`/catering/edit/${row.id}`}
    //         className="bg-secondary text-white text-md rounded-lg py-1 px-2"
    //       >
    //         Edit
    //       </Link>

    //       <Link
    //         to={`/catering/delete/${row.id}`}
    //         className="bg-danger text-white text-md rounded-lg py-1 px-2"
    //       >
    //         Delete
    //       </Link>
    //     </div>
    //   ),
    // },
  ];

  const load = () => {
    if(data.length > 0) {
      setIsLoading(false);
      return
    };
  };

  const filteredItems = data.filter((item) => {
    if (
      (item.coach_name &&
        item.coach_name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.member_name &&
        item.member_name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.schedule_datetime &&
        item.schedule_datetime
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item.activity_number &&
        item.activity_number
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item.type &&
        item.type.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.status &&
        item.status.toLowerCase().includes(filterText.toLowerCase()))
    ) {
      return item;
    }
  });

  useEffect(() => {
    load();

    async function get() {
      for( let i = 0; i < dataActivity.length; i++ ) {
        const getMember = await fetch(
          `${APIBASEURL}/member/${dataActivity[i].member_id}`,
          requestSetting("GET")
        );
        const getCoach = await fetch(
          `${APIBASEURL}/coach/${dataActivity[i].coach_id}`,
          requestSetting("GET")
        );
        
        const resMember = await getMember.json();
        const resCoach = await getCoach.json();

        const member = resMember;
        const coach = resCoach;

        dataActivity[i].member_name = member.name;
        dataActivity[i].coach_name = coach.name;
      }

      setData(dataActivity)
    }

    get();
  }, [dataActivity, data]);

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <div className="flex  justify-between">
            <h4 className="text-primary mb-10 font-semibold text-lg">
              Activities
            </h4>
            <div className="flex flex-col items-end mt-8">
              {/* <Link type="submit" to="add" className="button bg-success">
                Add Catering
              </Link> */}
              <input
                type="text"
                className="border mb-3 rounded-lg mt-4 px-4 py-2 w-96 focus:outline-none"
                onChange={(e) => setFilterText(e.target.value)}
                placeholder="Search"
              />
            </div>
          </div>
          <DataTables columns={columns} data={filteredItems} />
        </div>
      )}
    </div>
  );
};

export default Activity;
