import React, { useEffect, useState } from "react";

const FecthGet = (url) => {
  const [data, setData] = useState([]);
  const token = localStorage.getItem("key");

  const requestSetting = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getApi = async () => {
    const API = await fetch(url, requestSetting);
    const res = await API.json();
    const data = res;

    setData(data);
  };

  useEffect(() => {
    getApi();
  }, [url]);

  return data.data;
};

const FecthById = (url) => {
  const [data, setData] = useState([]);
  const token = localStorage.getItem("key");

  const requestSetting = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const get = async () => {
    const API = await fetch(url, requestSetting);
    const res = await API.json();

    setTimeout(() => {
      setData(res);
    }, 200);
  };

  useEffect(() => {
    get();
  }, [url]);

  return data;
};

const FecthPost = (url, data) => {
  const token = localStorage.getItem("key");

  const requestSetting = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  const getApi = async () => {
    const API = await fetch(url, requestSetting);
    const res = await API.json();

    return res;
  };

  return getApi();
};

const FecthUpdate = (url, data) => {
  const token = localStorage.getItem("key");

  const requestSetting = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  const getApi = async () => {
    const API = await fetch(url, requestSetting);
    const res = await API.ok;

    return res;
  };

  return getApi();
};



const FecthDelete = (url) => {
  const token = localStorage.getItem("key");

  const requestSetting = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const get = async () => {
    const API = await fetch(url, requestSetting);
    const res = await API;
    return res.ok;
  };

  return get();
};

const FecthPostAsset = async (files, name) => {
  const formdata = new FormData();

  if (files == undefined || name == "") return;
  formdata.append("file", files, name);

  const requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  const API = await fetch(
    "https://api.fitwell.nstek.dev/user/util/upload/assets",
    requestOptions
  );
  const res = await API.json();
  const urlImage = res.url;

  return urlImage;
};

const FecthLogout = async () => {
  const URLOGOUT = "https://api.fitwell.nstek.dev/user/auth/logout";
  const token = localStorage.getItem("key");

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    redirect: "follow",
  };

  const API = await fetch(URLOGOUT, requestOptions);
  const res = await API.ok;

  return res;
};

export {
  FecthGet,
  FecthPost,
  FecthUpdate,
  FecthById,
  FecthDelete,
  FecthPostAsset,
  FecthLogout,
};
