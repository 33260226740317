import React, { useState } from "react";
import { useEffect } from "react";
import { GoTrashcan } from "react-icons/go";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../components";
import {
  DeleteData,
  GetData,
  GetDataById,
  PostData,
  UpdateData,
} from "../../../config/service/ApiURL";

const EditCompany = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [coachData, setCoachData] = useState([]);
  const [coachIsAlready, setCoachIsAlready] = useState(false);
  const [coachIsSelected, setcoachIsSelected] = useState("");
  const [deleteCoach, setDeleteCoach] = useState([]);
  const [FormData, setFormData] = useState({
    name: "",
    pic: "",
    mail_domain: "",
    expired_date: "",
    code: "",
    status: "Active",
  });
  const navigate = useNavigate();
  const params = useParams();
  const dataCoachAll = GetData("coach");
  const dataCompany = GetDataById(`company/${params.companyId}`);
  const dataCoach = GetData(`company/${params.companyId}/coach`);

  const load = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  const handleChange = (coachId) => {
    setCoachIsAlready(false);
    coachData.forEach((coach) => {
      if (coach.id === coachId) setCoachIsAlready(true);
    });
  };

  const handleCooseCoach = (coachId) => {
    setCoachIsAlready(true);
    if (coachData.length == 0) setCoachData([...coachData, dataCoach[0]]);
    if (coachIsAlready) return;
    dataCoachAll.forEach((coach) => {
      if (coachId === coach.id) {
        setCoachData([...coachData, coach]);
      }
    });

    const deletedCoach = deleteCoach.filter((coach) => coach.id == coachId);
    setDeleteCoach(deletedCoach);
  };

  const handleDeleteCoachIsPick = (coachId) => {
    const newCoach = coachData.filter((coach) => {
      return coach.id != coachId;
    });

    setDeleteCoach([...deleteCoach, coachId]);
    setCoachData(newCoach);
  };

  const renderCoach = () => {
    let newCoach = [];
    if (dataCoach != undefined) {
      dataCoach.forEach((coach) => {
        newCoach.push(coach.coach);
      });
    }

    setCoachData(newCoach);
  };

  const handleSaveCompany = async () => {
    setIsLoading(true);

    if (
      FormData.name == "" ||
      FormData.code == "" ||
      FormData.mail_domain == "" ||
      FormData.pic == "" ||
      FormData.status == "" ||
      FormData.expired_date == ""
    ) {
      window.alert("Field Tidak Boleh Kosong");
      setIsLoading(false);
      return;
    }

    if (FormData.code.length > 15) {
      window.alert("Company Code Tidak Boleh Lebih Dari 15 Karakter");
      setIsLoading(false);
      return;
    }

    const editCompany = UpdateData(`company/${params.companyId}`, FormData);
    if (editCompany) navigate("/company");

    coachData.forEach(async (coachNew) => {
      await PostData(`company/${params.companyId}/coach`, {
        coach_id: coachNew.id,
      });
    });

    if (deleteCoach != []) {
      deleteCoach.forEach((coach) => {
        DeleteData(`company/${params.companyId}/coach/${coach}`);
      });
    }
  };

  useEffect(() => {
    if (dataCoach) {
      load();
      renderCoach();
    }

    if (dataCoachAll != undefined && dataCoachAll != []) {
      const coachFirst = dataCoachAll[0] != undefined ? dataCoachAll[0].id : [];

      setcoachIsSelected(coachFirst);
    }

    setFormData({
      name: dataCompany.name,
      pic: dataCompany.pic,
      mail_domain: dataCompany.mail_domain,
      expired_date: dataCompany.expired_date,
      code: dataCompany.code,
      status: dataCompany.status,
    });
  }, [dataCoach, dataCompany]);

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <h4 className="text-primary font-semibold text-lg">Company</h4>

          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className="lg:w-[50rem] w-full mt-[45px]"
          >
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
              <div className="input-form-box">
                <label htmlFor="name">Name : </label>
                <input
                  type="text"
                  id="name"
                  placeholder="name"
                  required
                  value={FormData.name || ""}
                  onChange={(e) =>
                    setFormData({ ...FormData, name: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="pic">Pic : </label>
                <input
                  type="text"
                  id="pic"
                  placeholder="pic"
                  value={FormData.pic || ""}
                  required
                  onChange={(e) =>
                    setFormData({ ...FormData, pic: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="company_code">Company Code : </label>
                <input
                  type="text"
                  id="company_code"
                  placeholder="company code"
                  required
                  value={FormData.code || ""}
                  onChange={(e) =>
                    setFormData({ ...FormData, code: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="main_domain">Mail Domain : </label>
                <input
                  type="text"
                  id="main_domain"
                  placeholder="mail domain"
                  required
                  value={FormData.mail_domain || ""}
                  onChange={(e) =>
                    setFormData({ ...FormData, mail_domain: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="status">Status :</label>
                <select
                  name="status"
                  id="status"
                  required
                  value={FormData.status || ""}
                  onChange={(e) =>
                    setFormData({ ...FormData, status: e.target.value })
                  }
                >
                  <option value="Active">Active</option>
                  <option value="Non Active">Non Active</option>
                </select>
              </div>
              <div className="input-form-box">
                <label htmlFor="expired_date">Expired Date : </label>
                <input
                  type="date"
                  id="expired_date"
                  placeholder="Expired Date"
                  required
                  value={FormData.expired_date || ""}
                  onChange={(e) =>
                    setFormData({ ...FormData, expired_date: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="mt-12 grid grid-cols-1 gap-5 items-end">
              <div className="input-form-box -my-5 ">
                <h3 className="font-bold text-sm mb-1 ">Coach</h3>
                <label htmlFor="coach">Choose coach to add</label>
                <div className="flex flex-wrap gap-5">
                  <select
                    name="coach"
                    id="coach"
                    className="sm:w-[24.5rem] w-full"
                    onChange={(e) => {
                      setcoachIsSelected(e.target.value);
                      handleChange(e.target.value);
                    }}
                  >
                    {dataCoachAll != undefined
                      ? dataCoachAll.map((coach) => (
                          <option value={coach.id} key={coach.id}>
                            {coach.name} ({coach.type})
                          </option>
                        ))
                      : ""}
                  </select>
                  <button
                    type="submit"
                    onClick={() => handleCooseCoach(coachIsSelected)}
                    className="w-20 h-10  text-center rounded-lg text-white  bg-success"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-10 grid md:grid-cols-2 grid-cols-1 gap-5 ">
              {coachData != [] && !coachData.includes(null)
                ? coachData.map((coach, index) => (
                    <div
                      className="rounded-lg relative h-[80px] px-5 flex items-center gap-5 bg-bg-grey-young"
                      key={index}
                    >
                      <div className="w-12 h-12 border border-primary rounded-full overflow-hidden">
                        <img
                          src={coach.photo}
                          alt={coach.name}
                          className="w-full "
                        />
                      </div>
                      <div>
                        <h1 className="font-semibold text-sm">{coach.name}</h1>
                        <p className="text-sm">{coach.type}</p>
                      </div>
                      <GoTrashcan
                        className="text-danger text-2xl absolute bottom-3 right-3 cursor-pointer"
                        onClick={() => {
                          handleDeleteCoachIsPick(coach.id);
                        }}
                      />
                    </div>
                  ))
                : ""}
            </div>
            {coachData.length == 0 && (
              <div className="lg:w-[40rem] w-full bg-bg-grey-young h-[5rem] rounded-lg flex justify-center items-center font-medium text-slate-300">
                <h5>No coach yet</h5>
              </div>
            )}
            <div className="flex item-center gap-2">
              <Link type="submit" to="/company" className="button bg-danger">
                Cancel
              </Link>
              <button
                type="submit"
                className="button bg-success"
                onClick={handleSaveCompany}
              >
                Save Company
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditCompany;
