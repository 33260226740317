import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Loading } from '../../../components';
import { GetData, UpdateData } from '../../../config/service/ApiURL';

const EditCategory = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({
        category: "",
    })
    const dataCategory = GetData("member_category");
    const params = useParams();
    const navigate = useNavigate();

    const handleEditCategory = async () => {
        if(formData.category == "") return;

        const res = await UpdateData(`member/${params.memberId}`, FormData);
        if(res) return navigate("member");
    }

    const load  = () => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000)
    } 

    useEffect(() => {
        if(dataCategory != undefined || dataCategory != []) load();
    }, [dataCategory])

  return (
    <div>{isLoading && <Loading />}
      {!isLoading && (
        <div>
          <h4 className="text-primary font-semibold text-lg">Members</h4>
          <form
            onSubmit={(e) => handleEditCategory(e)}
            className="lg:w-[50rem] w-full mt-[45px]"
          >
            <div className="grid md:grid-cols-2 grid-cols-1 gap-5 ">
              <div className="input-form-box">
                <label htmlFor="category">Category : </label>
                <select name="category" id="category" 

                  onChange={(e) =>
                    setFormData({ ...FormData, category: e.target.value })
                  }>
                    {dataCategory.map(({id, name}) => (
                        <option value={id} key={id}>{name}</option>
                    ))}
                </select>
              </div>
              {/* <div className="input-form-box">
            <label htmlFor="name">Name : </label>
            <input
              type="text"
              id="name"
              value={FormData.name || ""}
              onChange={(e) =>
                setFormData({ ...FormData, name: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="age">Age : </label>
            <input
              type="number"
              id="age"
              value={FormData.age || ""}
              onChange={(e) =>
                setFormData({ ...FormData, age: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="gender">Gender :</label>
            <select
              name="gender"
              id="gender"
              value={FormData.gender || ""}
              onChange={(e) =>
                setFormData({ ...FormData, gender: e.target.value })
              }
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div className="input-form-box">
            <label htmlFor="height">Height : </label>
            <input
              type="number"
              id="height"
              value={FormData.height || ""}
              onChange={(e) =>
                setFormData({ ...FormData, height: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="weigth">Weigth : </label>
            <input
              type="number"
              id="weigth"
              value={FormData.weight || ""}
              onChange={(e) =>
                setFormData({ ...FormData, weight: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="email">Email : </label>
            <input
              type="email"
              id="email"
              value={FormData.email || ""}
              onChange={(e) =>
                setFormData({ ...FormData, email: e.target.value })
              }
            />
          </div>
          <div className="input-form-box">
            <label htmlFor="phone">Phone : </label>
            <input
              type="number"
              id="phone"
              value={FormData.phone || ""}
              onChange={(e) =>
                setFormData({ ...FormData, phone: e.target.value })
              }
            />
          </div>

          <div className="input-form-box">
            <label htmlFor="status">Status :</label>
            <select
              name="status"
              id="status"
              value={FormData.status || ""}
              onChange={(e) =>
                setFormData({ ...FormData, status: e.target.value })
              }
            >
              <option value="Active">Active</option>
              <option value="Non Active">Non Active</option>
            </select>
          </div>
          <div className="input-form-box">
            <label htmlFor="photo">Photo : </label>
            <input
              type="file"
              accept="image/*"
              id="photo"
              onChange={handleChangeImage}
            />
          </div> */}
            </div>
          </form>
          <div className="flex item-center gap-2 -mt-3">
            <Link type="submit" to="/member" className="button bg-danger">
              Cancel
            </Link>
            <button
              type="submit"
              className="button bg-success"
              onClick={handleEditCategory}
            >
              Save Password
            </button>
          </div>
        </div>
      )}</div>
  )
}

export default EditCategory;