import ReactLoading from "react-loading";

const Loading = () => {
  return (
    <div className="fixed w-full h-full top-0 items-center left-0 flex justify-center z-50 opacity-95">
      <div className="bg-white shadow-lg p-5 rounded-xl">
        <ReactLoading type="spin" color="#F77E21" height={64} width={64} />
        <h1 className="font-bold text-secondary mt-4">Loading</h1>
      </div>
    </div>
  );
};

export default Loading;
