import React from "react";
import DataTable from "react-data-table-component";

const DataTables = ({ columns, data }) => {
  const customStyles = {
    rows: {
      style: {
        minHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "10px", // override the cell padding for head cells
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        width: "5rem",
        paddingLeft: "0px", // override the cell padding for data cells
        marginRight: "px",
      },
    },
  };

  return (
    <div className="border border-bgrey">
      <DataTable
        columns={columns}
        data={data}
        pagination
        customStyles={customStyles}
      />
    </div>
  );
};

export default DataTables;
