import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import { APIBASEURL } from "../../../../config/service/ApiURL";
import { FecthGet, FecthPost } from "../../../../config/service/fecth";

const AddCompany = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [FormData, setFormData] = useState({
    company_id: "",
  });
  const navigate = useNavigate();
  const params = useParams();
  const URL = `${APIBASEURL}/company`;
  const URLADDCOMPANY = `${APIBASEURL}/coach/${params.coachId}/company`;
  const getCompany = FecthGet(URL);

  const handleAddCompany = async (e) => {
    e.preventDefault();

    const addCompany = await FecthPost(URLADDCOMPANY, FormData);
    if (addCompany)
      return navigate(`/coach/${params.coachId}/company`, { replace: true });
  };

  useEffect(() => {
    setTimeout(() => {
      if (getCompany === undefined) {
        setData([]);
        setLoading(true);
      }

      if (getCompany !== undefined) {
        setData(getCompany);
        setFormData({ company_id: data[0].id });
        setLoading(false);
      }
    }, 200);
  }, [data]);

  return (
    <div>
      <Link
        type="submit"
        to={`/admin/coach/${params.coachId}/company`}
        className="button-orange"
      >
        <IoArrowBackOutline className="text-3xl" />
        Back
      </Link>
      <form
        onSubmit={(e) => handleAddCompany(e)}
        className="p-8 bg-white shadow-lg rounded-lg lg:w-[50rem] w-full"
      >
        <h1 className="text-2xl font-semibold mb-8 text-secondary">
          Create New Company
        </h1>

        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 ">
          <div className="input-form-box">
            <label htmlFor="name">Name : </label>
            <select
              type="text"
              id="name"
              onChange={(e) => setFormData({ company_id: e.target.value })}
            >
              {data.map((company) => (
                <option value={company.id}>{company.name}</option>
              ))}
              {loading && "Loading...."}
            </select>
          </div>
        </div>
        <button type="submit" className="button-orange">
          Add
        </button>
      </form>
    </div>
  );
};

export default AddCompany;
