import React, { useEffect } from "react";
import { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { FaRegBuilding, FaTrash } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { HiUserAdd } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { DataTables, Loading } from "../../../components";
import { APIBASEURL } from "../../../config/service/ApiURL";
import { FecthGet } from "../../../config/service/fecth";

const Catering = () => {
  const [filterText, setFilterText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const data = FecthGet(`${APIBASEURL}/catering`);

  const handleConfirmDelete = (id) => {
    setIsLoading(true);

    const res = window.confirm("Sudah Yakin Ingin Dihapus?");
    if (res) navigate(`/catering/delete/${id}`);
  };

  const columns = [
    {
      name: "Photo",
      selector: (row) => row.image,
      sortable: true,
      cell: (row) => (
        <img
          className="w-16 rounded-md ml-2 my-2"
          alt={row.name}
          src={row.image}
        />
      ),
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <Link
          to={`/catering/detail/${row.id}`}
          className="text-table text-primary"
        >
          {row.name}
        </Link>
      ),
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      cell: (row) => (
        <p className="text-table">{row.description.slice(0, 80)}.....</p>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex flex-row items-center gap-2 ml-2">
          <Link
            to={`/catering/edit/${row.id}`}
            className="bg-secondary text-white text-md rounded-lg py-1 px-2"
          >
            Edit
          </Link>

          <button
            onClick={() => handleConfirmDelete(row.id)}
            className="bg-danger text-white text-md rounded-lg py-1 px-2"
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const load = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  const filteredItems =
    data === undefined
      ? ""
      : data.filter((item) => {
          if (
            item.name &&
            item.name.toLowerCase().includes(filterText.toLowerCase())
          ) {
            return item;
          }
        });

  useEffect(() => {
    if (data) load();
  }, [data]);

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <div className="flex  justify-between">
            <h4 className="text-primary mb-10 font-semibold text-lg">
              Catering Healty
            </h4>
            <div className="flex flex-col items-end">
              <Link type="submit" to="add" className="button bg-success">
                Add Catering
              </Link>
              <input
                type="text"
                className="border mb-3 rounded-lg mt-4 px-4 py-2 w-96 focus:outline-none"
                onChange={(e) => setFilterText(e.target.value)}
                placeholder="Search"
              />
            </div>
          </div>
          <DataTables columns={columns} data={filteredItems} />
        </div>
      )}
    </>
  );
};

export default Catering;
