import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../components";
import {
  GetDataById,
  PostData,
  UpdateData,
} from "../../../config/service/ApiURL";

const EditUser = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [menuPermission, setMenuPermission] = useState([]);
  const [menuPermissionDefault, setMenuPermissionDefault] = useState([]);
  const [FormData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    menu_permission: [],
  });
  const params = useParams();
  const navigate = useNavigate();
  const dataUser = GetDataById(`user/${params.userId}`);
  const company = useRef();
  const members = useRef();
  const coach = useRef();
  const activityData = useRef();
  const reportActivity = useRef();
  const article = useRef();
  const cateringHealty = useRef();
  const membersCategory = useRef();
  const topic = useRef();
  const userManagement = useRef();

  const load = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  const handleChangeMenuPermission = (e) => {
    const value = e.target.value;
    if (e.target.checked) setMenuPermission([...menuPermission, value]);
    if (!e.target.checked) {
      const newMenuPermission = menuPermission.filter((menu) => menu != value);
      setMenuPermission(newMenuPermission);
    }
  };

  const handleSaveUser = async () => {
    // setIsLoading(true);

    // if (FormData.name == "" || FormData.email == "") return;
    FormData.menu_permission = menuPermission.join("|");

    const res = await UpdateData(`user/${params.userId}`, FormData);
    if (res) {
      localStorage.setItem("permission", FormData.menu_permission);
      sessionStorage.setItem("isReload", true);

      navigate("/user-management", { replace: true });
    }
  };

  useEffect(() => {
    if (dataUser.menu_permission == undefined) {
      setIsLoading(true);
      return;
    }

    setTimeout(() => {
      menuPermissionDefault.filter((menu) => {
        switch (menu) {
          case "company":
            company.current.checked = true;
            break;
          case "members":
            members.current.checked = true;
            break;
          case "coach":
            coach.current.checked = true;
            break;
          case "activity data":
            activityData.current.checked = true;
            break;
          case "report activity":
            reportActivity.current.checked = true;
            break;
          case "article":
            article.current.checked = true;
            break;
          case "catering healty":
            cateringHealty.current.checked = true;
            break;
          case "members category":
            membersCategory.current.checked = true;
            break;
          case "topic":
            topic.current.checked = true;
            break;
          case "user management":
            userManagement.current.checked = true;
            break;
          default:
            break;
        }
      });
    }, 500);
  }, [menuPermissionDefault]);

  useEffect(() => {
    if (dataUser) load();
    if (dataUser != undefined || dataUser != []) {
      setTimeout(() => {
        const newMenuPermission = dataUser.menu_permission.split("|");
        setMenuPermission([...menuPermission, ...newMenuPermission]);
        setMenuPermissionDefault([
          ...menuPermissionDefault,
          ...newMenuPermission,
        ]);

        setFormData({
          name: dataUser.name,
          email: dataUser.email,
        });
      }, 1200);
    }
  }, [dataUser]);

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <h4 className="text-primary font-semibold text-lg">
            User Management
          </h4>
          <form
            className="lg:w-[50rem] w-full mt-[45px]"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
              <div className="input-form-box">
                <label htmlFor="name">Name : </label>
                <input
                  type="text"
                  id="name"
                  placeholder="name"
                  required
                  value={FormData.name || ""}
                  onChange={(e) =>
                    setFormData({ ...FormData, name: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="email">Email : </label>
                <input
                  type="email"
                  id="email"
                  placeholder="email"
                  required
                  value={FormData.email || ""}
                  onChange={(e) =>
                    setFormData({ ...FormData, email: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="password">Password : </label>
                <input
                  type="password"
                  id="password"
                  placeholder="password"
                  onChange={(e) =>
                    setFormData({ ...FormData, password: e.target.value })
                  }
                />
                <label htmlFor="passowrd">
                  (Kosongkan jika tidak mau diubah)
                </label>
              </div>
              <div className="input-form-box ml-2">
                <label htmlFor="name">Menu Permission : </label>
                <div className="flex gap-8">
                  <div className="flex flex-col gap-2">
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="company"
                        name="company"
                        value="company"
                        ref={company}
                        onChange={(e) => {
                          handleChangeMenuPermission(e);
                        }}
                      />
                      <label htmlFor="company" className="ml-2">
                        Company
                      </label>
                    </div>
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="members"
                        name="members"
                        value="members"
                        ref={members}
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="members" className="ml-2">
                        Members
                      </label>
                    </div>
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="coach"
                        name="coach"
                        value="coach"
                        ref={coach}
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="coach" className="ml-2">
                        Coach
                      </label>
                    </div>
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="activity data"
                        name="activity data"
                        value="activity data"
                        ref={activityData}
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="activity data" className="ml-2">
                        Activity Data
                      </label>
                    </div>
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="report activity"
                        name="report activity"
                        value="report activity"
                        ref={reportActivity}
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="report activity" className="ml-2">
                        Report Acticity
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="article"
                        name="article"
                        value="article"
                        ref={article}
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="article" className="ml-2">
                        Article
                      </label>
                    </div>
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="catering healty"
                        name="catering healty"
                        value="catering healty"
                        ref={cateringHealty}
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="catering healty" className="ml-2">
                        Catering Healthy
                      </label>
                    </div>
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="members category"
                        name="members category"
                        value="members category"
                        ref={membersCategory}
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="members category" className="ml-2">
                        Members Category
                      </label>
                    </div>
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="topic"
                        name="topic"
                        value="topic"
                        ref={topic}
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="topic" className="ml-2">
                        Topic
                      </label>
                    </div>
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        id="user management"
                        name="user management"
                        value="user management"
                        ref={userManagement}
                        onChange={(e) => handleChangeMenuPermission(e)}
                      />
                      <label htmlFor="user management" className="ml-2">
                        User Management
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex item-center gap-2 mt-4 ">
              <Link
                type="submit"
                to="/user-management"
                className="button bg-danger"
              >
                Cancel
              </Link>
              <button
                type="submit"
                className="button bg-success"
                onClick={handleSaveUser}
              >
                Save User
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default EditUser;
