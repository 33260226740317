import React, { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { HiUserAdd } from "react-icons/hi";
import { RiArticleLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { DataTables, Loading } from "../../../components";
import { APIBASEURL } from "../../../config/service/ApiURL";
import { FecthGet } from "../../../config/service/fecth";

const Article = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();
  let data = [];
  const URL = `${APIBASEURL}/article`;
  const getArticle = FecthGet(URL);

  const handleConfirmDelete = (id) => {
    setIsLoading(true);

    const res = window.confirm("Apakah Sudah Yakin Ingin Dihapus?");
    if (res) navigate(`/article/delete/${id}`);
  };

  const columns = [
    {
      name: "Image",
      selector: (row) => row.image,
      sortable: true,
      cell: (row) => (
        <img
          src={row.image}
          alt={row.name}
          className="rounded-lg w-16 ml-2 my-2"
        />
      ),
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      cell: (row) => (
        <Link className="text-table text-primary" to={`detail/${row.id}`}>
          {row.title.substring(0, 40)}
          ....
        </Link>
      ),
    },
    {
      name: "URL",
      selector: (row) => row.url,
      sortable: true,
      cell: (row) => (
        <p className="text-table">
          {row.url !== null ? row.url.slice(0, 18) : ""}...
        </p>
      ),
    },
    {
      name: "Short Description",
      selector: (row) => row.short_desc,
      sortable: true,
      cell: (row) => (
        <p className="text-table my-2">
          {row.short_desc !== null ? row.short_desc.substring(0, 40) : ""}...
        </p>
      ),
    },
    {
      name: "Article Date",
      selector: (row) => row.article_date,
      sortable: true,
      cell: (row) => <p className="text-table">{row.article_date}</p>,
    },

    {
      name: "Action",
      cell: (row) => (
        <div className="flex flex-row items-center gap-2 ml-2">
          <Link
            to={`/article/edit/${row.id}`}
            className="bg-secondary text-white text-md rounded-lg py-1 px-2"
          >
            Edit
          </Link>

          <button
            onClick={() => handleConfirmDelete(row.id)}
            className="bg-danger text-white text-md rounded-lg py-1 px-2"
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  if (getArticle !== undefined) {
    getArticle.forEach((article) => {
      const newArticle = {
        id: article.id,
        title: article.title,
        short_desc: article.short_desc,
        article_date: article.article_date,
        url: article.url,
        image: article.image,
      };

      data.push(newArticle);
    });
  }

  const filteredItems = data.filter((item) => {
    if (
      (item.title &&
        item.title.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.content != null &&
        item.content.toLowerCase().includes(filterText.toLowerCase()))
    ) {
      return item;
    }
  });

  const load = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  useEffect(() => {
    if (getArticle || getArticle == undefined) load();
  }, []);

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <div className="flex  justify-between">
            <h4 className="text-primary mb-10 font-semibold text-lg">
              Article
            </h4>
            <div className="flex flex-col items-end">
              <Link type="submit" to="add" className="button bg-success">
                Add Article
              </Link>
              <input
                type="text"
                className="border mb-3 rounded-lg mt-4 px-4 py-2 w-96 focus:outline-none"
                onChange={(e) => setFilterText(e.target.value)}
                placeholder="Search"
              />
            </div>
          </div>
          <DataTables columns={columns} data={filteredItems} />
        </div>
      )}
    </div>
  );
};

export default Article;
