import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APIBASEURL } from "../../../config/service/ApiURL";
import { FecthDelete } from "../../../config/service/fecth";

const DeleteCatering = () => {
  const navigate = useNavigate();
  const params = useParams();
  const URL = `${APIBASEURL}/catering/${params.cateringId}`;
  const dataCatering = FecthDelete(URL);

  useEffect(() => {
    if (dataCatering) {
      navigate("/catering", { replace: true });
      return;
    }
  });
};

export default DeleteCatering;
