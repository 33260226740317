import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APIBASEURL } from "../../../../config/service/ApiURL";
import { FecthDelete } from "../../../../config/service/fecth";

const DeleteCompany = () => {
  const navigate = useNavigate();
  const params = useParams();
  const URL = `${APIBASEURL}/coach/${params.coachId}/company/${params.companyId}`;
  useEffect(() => {
    const deleteCompany = FecthDelete(URL);
    if (deleteCompany)
      return navigate(`/coach/${params.coachId}/company`, { replace: true });
  }, []);
};

export default DeleteCompany;
