import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FecthPost, FecthPostAsset } from "../../../config/service/fecth";
import { WithOutContext as ReactTags } from "react-tag-input";
import { AiFillCloseCircle } from "react-icons/ai";
import { TagsInput } from "../../../components";
import { Loading } from "../../../components";
import { APIBASEURL } from "../../../config/service/ApiURL";

const AddCatering = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [benefitDeleted, setBenefitDeleted] = useState([]);
  const [benefit, setBenefit] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [imageDirectory, setImageDirectory] = useState("");
  const [preview, setPreview] = useState("");
  const [FormData, setFormData] = useState({
    name: "",
    description: "",
    image: "",
    benefit: [],
  });
  const navigate = useNavigate();
  const params = useParams();

  const URL = `${APIBASEURL}/catering`;

  const handleChangeImage = (e) => {
    setSelectedImage(e.target.files[0]);
    setImageDirectory(e.target.value);
  };

  const handleAddCatering = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const image = await FecthPostAsset(selectedImage, imageDirectory);
    if (selectedImage) FormData.image = image;
    FormData.benefit = benefit;

    const res = await FecthPost(URL, FormData);
    if (res) return navigate("/catering", { replace: true });
  };

  useEffect(() => {
    if (selectedImage !== undefined) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  }, [selectedImage, preview]);

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <h4 className="text-primary mb-10 font-semibold text-lg">
            Catering - Add
          </h4>
          <div className="preview-img">
            <img src={preview} />
          </div>
          <form
            onSubmit={(e) => handleAddCatering(e)}
            className=" lg:w-[50rem] w-full -mt-[10px]"
          >
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4  ">
              <div className="input-form-box">
                <label htmlFor="name">Name : </label>
                <input
                  type="text"
                  id="name"
                  placeholder="name"
                  onChange={(e) =>
                    setFormData({ ...FormData, name: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="image">Image : </label>
                <input
                  type="file"
                  accept="image/*"
                  id="image"
                  onChange={handleChangeImage}
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="description">Description : </label>
                <textarea
                  name="description"
                  id="description"
                  placeholder="description"
                  onChange={(e) =>
                    setFormData({ ...FormData, description: e.target.value })
                  }
                ></textarea>
              </div>
              <TagsInput
                setBenefit={setBenefit}
                benefitDeleted={benefitDeleted}
                setBenefitDeleted={setBenefitDeleted}
              />
            </div>
            <div className="flex items-center gap-2">
              <Link type="submit" to="/catering" className="button bg-danger">
                Cancel
              </Link>
              <button className="button bg-success" type="submit ">
                Save Catering
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddCatering;
