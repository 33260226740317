import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataTables, Loading } from "../../../components";
import { APIBASEURL, GetData, requestSetting } from "../../../config/service/ApiURL";

const Member = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [member, setMember] = useState([]);;
  const navigate = useNavigate();
  const getMember = GetData('member');

  const handleDeleteConfirm = (id) => {
    let res = window.confirm("Sudah Yakin Ingin Dihapus");
    if (res) navigate(`/member/delete/${id}`);
  };

  const columns = [
    {
      name: "Photo",
      selector: (row) => row.photo,
      sortable: true,
      cell: (row) => (
        <img
          src={row.photo}
          alt={row.name}
          className="rounded-lg w-16 text-table my-2"
        />
      ),
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <Link className="text-table text-primary" to={`detail/${row.id}`}>
          {row.name}
        </Link>
      ),
    },
    {
      name: "Category",
      selector: (row) => row.category_name,
      sortable: true,
      cell: (row) => <p className="text-table">{row.category_name}</p>,
    },
    {
      name: "Age",
      selector: (row) => row.age,
      sortable: true,
      cell: (row) => <p className="text-table">{row.age}</p>,
    },
    // {
    //   name: "Gender",
    //   selector: (row) => row.gender,
    //   sortable: true,
    //   cell: (row) => <p className="text-table">{row.gender}</p>,
    // },
    // {
    //   name: "Phone",
    //   selector: (row) => row.phone,
    //   sortable: true,
    //   cell: (row) => <p className="text-table">{row.phone}</p>,
    // },
    // {
    //   name: "Email",
    //   selector: (row) => row.email,
    //   sortable: true,
    //   cell: (row) => <p className="text-table">{row.email}</p>,
    // },
    // {
    //   name: "Height",
    //   selector: (row) => row.height,
    //   sortable: true,
    //   cell: (row) => <p className="text-table">{row.height}</p>,
    // },
    // {
    //   name: "Weight",
    //   selector: (row) => row.weight,
    //   sortable: true,
    //   cell: (row) => <p className="text-table">{row.weight}</p>,
    // },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => <p className="text-table">{row.status}</p>,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex flex-row items-center gap-2 ml-2">
          <Link
            to={`edit/${row.id}`}
            className="bg-secondary text-white text-md rounded-lg py-1 px-2"
          >
            Edit
          </Link>
          <button
            onClick={() => handleDeleteConfirm(row.id)}
            className="bg-danger text-white text-md rounded-lg py-1 px-2"
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const load = () => {
    if (member.length > 0) {
      console.log(member)
      setIsLoading(false);
      return;
    };
  };

  const filteredItems = member.filter((item) => {
    if (
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.gender != null &&
        item.gender.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.phone != null &&
        item.phone.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email != null &&
        item.email.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.status &&
        item.status.toLowerCase().includes(filterText.toLowerCase()))
    ) {
      return item;
    }
  });

  useEffect(() => {
    load();

     async function get() {
      for(let i = 0; i < getMember.length; i++) {
        const category = await fetch(
              `${APIBASEURL}/member_category/${getMember[i].member_category_id}`,
              requestSetting("GET")
          );

        const resCategory = await category.json();
        const dataCategory = resCategory.name;

        getMember[i].category_name = dataCategory;
      } 
      setMember(getMember);
     }
     
     get();
  }, [member, getMember]);



  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <div className="flex items-center justify-between">
            <h4 className="text-primary mb-10 font-semibold text-lg">
              Members
            </h4>
            <input
              type="text"
              className="border mb-3 rounded-lg mt-4 px-4 py-2 w-96 focus:outline-none"
              onChange={(e) => setFilterText(e.target.value)}
              placeholder="Search"
            />
          </div>
          <DataTables columns={columns} data={filteredItems} />
        </div>
      )}
    </div>
  );
};

export default Member;
