import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APIBASEURL } from "../../../../config/service/ApiURL";
import { FecthDelete } from "../../../../config/service/fecth";

const DeleteCoachCompanies = () => {
  const navigate = useNavigate();
  const params = useParams();
  const URL = `${APIBASEURL}/company/${params.companyId}/coach/${params.coachId}`;
  useEffect(() => {
    const deleteCompany = FecthDelete(URL);
    console.log(deleteCompany);
    if (deleteCompany)
      return navigate(`/company/${params.companyId}/coach`, {
        replace: true,
      });
  }, []);
};

export default DeleteCoachCompanies;
