import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../../../components";
import {
  APIBASEURL,
  GetData,
  GetDataById,
  requestSetting,
} from "../../../config/service/ApiURL";

const DetailCompany = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [mode, setMode] = useState("");
  const [message, setMessage] = useState("");
  const [countMember, setCountMember] = useState(0);
  const [member, setMember] = useState([]);
  const [memberCompany, setMemberCompany] = useState([]);
  const params = useParams();
  const dataCompany = GetDataById(`company/${params.companyId}`);
  const dataCoach = GetDataById(`company/${params.companyId}/coach`).data;

  const load = () => {
    if (
      (dataCoach != undefined && dataCoach.length > 0) ||
      (memberCompany != undefined && memberCompany.length > 0)
    ) {
      setIsLoading(false);
      return;
    }
  };

  useEffect(() => {
    async function get() {
      const API = await fetch(`${APIBASEURL}/member`, requestSetting("GET"));
      const res = await API.json();
      const data = res.data;
      let members = [];
      for (let i = 0; i < data.length; i++) {
        const category = await fetch(
          `${APIBASEURL}/member_category/${data[i].member_category_id}`,
          requestSetting("GET")
        );

        const resCategory = await category.json();
        const dataCategory = resCategory;

        if (data[i].member_category_id === dataCategory.id)
          data[i].category_name = dataCategory.name;
        if (data[i].company_id === params.companyId) members.push(data[i]);
      }

      const dataByCompany = data.filter(
        (member) => member.company_id === params.companyId
      );
      setMemberCompany(dataByCompany);
    }

    get();
  }, []);

  const filteredMember = memberCompany.filter((member) => {
    const { name, category_name } = member;

    if (
      mode === "All" &&
      name &&
      name.toLowerCase().includes(filterText.toLowerCase())
    ) {
      return member;
    }
    if (
      category_name.toLowerCase() === mode.toLowerCase() &&
      name &&
      name.toLowerCase().includes(filterText.toLowerCase())
    ) {
      return member;
    }
    console.log(category_name);
  });

  const handleFilterButton = (e) => {
    setMode(e.target.textContent);
  };

  useEffect(() => {
    setCountMember(filteredMember.length);
  }, [filteredMember]);

  useEffect(() => {
    load();
    setMode("All");
  }, [memberCompany]);

  useEffect(() => {
    if (countMember > 0) setMessage("");
    if (countMember === 0) setMessage("Member Not Found");
  }, [countMember, filterText]);
  console.log(mode);

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div className="grid xl:grid-cols-4 2xl:grid-cols-1">
          <div className="2xl:flex rounded-xl w-full xl:col-span-1 col-auto">
            <ul className="flex flex-col gap-1 w-[20rem]">
              <h4 className="text-primary font-semibold text-link-sidebar mb-8">
                Company
              </h4>
              <li className="company-list-detail">
                <span> Company</span>
                {dataCompany.name}
              </li>
              <li className="company-list-detail">
                <span>Pic </span> {dataCompany.pic}
              </li>
              <li className="company-list-detail">
                <span> Mail domain </span>
                {dataCompany.mail_domain}
              </li>
              <li className="company-list-detail">
                <span>Expired Date </span>
                {dataCompany.expired_date}
              </li>
              <li className="company-list-detail">
                <span> Status </span>
                {dataCompany.status}
              </li>
            </ul>
            <div className="2xl:ml-7">
              <h4 className="text-primary font-semibold text-sm mb-8 mt-8 2xl:mt-2">
                Coach in Charge
              </h4>
              {dataCoach != [] &&
                dataCoach != undefined &&
                dataCoach.map((coach) => {
                  const { id, name, photo, type } = coach.coach;

                  return (
                    <div className="flex gap-8 mb-4 items-center" key={id}>
                      <div className="overflow-hidden w-14 h-14 rounded-full border-2 border-primary">
                        <img src={photo} alt={name} className="w-full " />
                      </div>
                      <div>
                        <h4 className="font-bold text-semi-black">{name}</h4>
                        <p>{type}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="xl:col-span-3 2xl:col-auto  pl-16 2xl:pl-2 pt-14">
            <div className="button-category flex gap-3">
              <button
                className={`${mode === "All" ? "active" : ""}`}
                onClick={(e) => handleFilterButton(e)}
              >
                All
              </button>
              <button
                className={`${mode === "BOD" ? "active" : ""}`}
                onClick={(e) => handleFilterButton(e)}
              >
                BOD
              </button>
              <button
                className={`${mode === "Prioritas" ? "active" : ""}`}
                onClick={(e) => handleFilterButton(e)}
              >
                Prioritas
              </button>
              <button
                className={`${mode === "Reguler" ? "active" : ""}`}
                onClick={(e) => handleFilterButton(e)}
              >
                Reguler
              </button>
            </div>
            <div className="flex items-center gap-4 mt-4 h-[3rem]">
              <input
                type="text"
                className="border border-bgrey rounded-xl w-[26rem] h-full px-3 focus:outline-none mb-1 borde"
                onChange={(e) => {
                  setFilterText(e.target.value);
                }}
                placeholder="Search"
              />
              <button className="rounded-lg text-white px-6 h-full bg-primary">
                Search
              </button>
              <h5>
                <span className="font-semibold">{countMember}</span> Member
                Found
              </h5>
            </div>
            <div className="shadow-md rounded-lg mt-4 overflow-auto h-[30rem]  xl:w-[48rem] w-[100%]">
              <table className="w-full relative ">
                <thead className="text-left bg-semi-black text-white sticky top-0 w-full ">
                  <tr>
                    <th className="pl-5 py-2  font-medium ">Name</th>
                    <th className="font-medium ">Category</th>
                    <th className="font-medium ">Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="mx-auto font-semibold absolute left-[39%] top-[5rem]">
                        {message != "" ? message : ""}
                      </span>
                    </td>
                  </tr>
                  {filteredMember != []
                    ? filteredMember.map((member) => (
                        <tr key={member.id}>
                          <td className="pl-5 flex gap-4 item-center my-4">
                            <div className="overflow-hidden w-12 h-12 rounded-full">
                              <img
                                src={member.photo}
                                alt={member.name}
                                className="w-full"
                              />
                            </div>
                            <div>
                              <h4 className="font-bold text-semi-black">
                                {member.name}
                              </h4>
                              <p className="text-semi-black">{member.gender}</p>
                            </div>
                          </td>
                          <td>{member.category_name}</td>
                          <td className="text-semi-black">{member.email}</td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailCompany;
