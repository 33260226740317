import React, { useState } from "react";
import { GoTrashcan } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Form, Loading } from "../../../components";
import { GetData, PostData } from "../../../config/service/ApiURL";

const AddCompany = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [coachData, setCoachData] = useState([]);
  const [coachIsAlready, setCoachIsAlready] = useState(false);
  const [coachIsSelected, setcoachIsSelected] = useState("");
  const [FormData, setFormData] = useState({
    name: "",
    pic: "",
    mail_domain: "",
    expired_date: "",
    code: "",
    status: "Active",
  });

  const navigate = useNavigate();
  const dataCoach = GetData("coach");

  const handleChangeCoach = (coachId) => {
    setCoachIsAlready(false);

    coachData.forEach((coach) => {
      if (coach.id === coachId) setCoachIsAlready(true);
    });
  };

  const handleCooseCoach = (coachId) => {
    setCoachIsAlready(true);
    if (coachData.length == 0) setCoachData([...coachData, dataCoach[0]]);
    if (coachIsAlready) return;

    dataCoach.forEach((coach) => {
      if (coachId === coach.id) {
        setCoachData([...coachData, coach]);
      }
    });
  };

  const handleDeleteCoachIsPick = (coachId) => {
    const newCoach = coachData.filter((coach) => {
      return coach.id != coachId;
    });

    setCoachData(newCoach);
  };

  const handleSaveCompany = async () => {
    setIsLoading(true);

    if (
      FormData.name == "" ||
      FormData.code == "" ||
      FormData.mail_domain == "" ||
      FormData.pic == "" ||
      FormData.status == "" ||
      FormData.expired_date == ""
    ) {
      window.alert("Field Tidak Boleh Kosong");
      setIsLoading(false);
      return;
    }

    if (FormData.code.length > 15) {
      window.alert("Company Code Tidak Boleh Lebih Dari 15 Karakter");
      setIsLoading(false);
      return;
    }

    if (coachData.length == 0) {
      window.alert("Pilih Coach Terlebih Dahulu");
      setIsLoading(false);
      return;
    }

    const addCompany = await PostData("company", FormData);
    const companyId = addCompany.id;

    coachData.forEach(async (coach) => {
      if (!coach.id) return;

      const res = await PostData(`company/${companyId}/coach`, {
        coach_id: coach.id,
      });

      if (res) {
        navigate("/company", { replace: true });
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (dataCoach) {
      setIsLoading(false);
    }
  }, []);
  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <h4 className="text-primary font-semibold text-lg">Company</h4>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className="lg:w-[50rem] w-full mt-[45px]"
          >
            {/* <h1 className="text-2xl font-semibold mb-8 text-secondary">
          Create New Companies
        </h1> */}
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
              <div className="input-form-box">
                <label htmlFor="name">Name : </label>
                <input
                  type="text"
                  id="name"
                  placeholder="name"
                  required
                  onChange={(e) =>
                    setFormData({ ...FormData, name: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="pic">Pic : </label>
                <input
                  type="text"
                  id="pic"
                  placeholder="pic"
                  required
                  onChange={(e) =>
                    setFormData({ ...FormData, pic: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="company_code">Company Code : </label>
                <input
                  type="text"
                  id="company_code"
                  placeholder="company code"
                  required
                  onChange={(e) =>
                    setFormData({ ...FormData, code: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="main_domain">Mail Domain : </label>
                <input
                  type="text"
                  id="main_domain"
                  placeholder="mail domain"
                  required
                  onChange={(e) =>
                    setFormData({ ...FormData, mail_domain: e.target.value })
                  }
                />
              </div>
              <div className="input-form-box">
                <label htmlFor="status">Status :</label>
                <select
                  name="status"
                  id="status"
                  required
                  onChange={(e) =>
                    setFormData({ ...FormData, status: e.target.value })
                  }
                >
                  <option value="Active">Active</option>
                  <option value="Non Active">Non Active</option>
                </select>
              </div>
              <div className="input-form-box">
                <label htmlFor="expired_date">Expired Date : </label>
                <input
                  type="date"
                  id="expired_date"
                  placeholder="Expired Date"
                  required
                  onChange={(e) =>
                    setFormData({ ...FormData, expired_date: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="mt-[45px] grid grid-cols-1 gap-5 items-end">
              <div className="input-form-box">
                <h3 className="font-bold text-sm">Coach</h3>
                <label htmlFor="coach">Choose coach to add</label>
                <div className="flex flex-wrap gap-5">
                  <select
                    name="coach"
                    id="coach"
                    required
                    className="sm:w-[24.5rem] w-full "
                    onChange={(e) => {
                      handleChangeCoach(e.target.value);
                      setcoachIsSelected(e.target.value);
                    }}
                  >
                    {dataCoach != undefined
                      ? dataCoach.map((coach) => (
                          <option value={coach.id} key={coach.id}>
                            {coach.name} ({coach.type})
                          </option>
                        ))
                      : ""}
                  </select>
                  <button
                    type="submit"
                    onClick={() => handleCooseCoach(coachIsSelected)}
                    className="w-20 h-10  text-center rounded-lg text-white  bg-success"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-4 grid md:grid-cols-2 grid-cols-1 gap-5">
              {coachData != []
                ? coachData.map((coach, index) => (
                    <div
                      className="rounded-lg relative h-[80px] px-5 flex items-center gap-5 bg-bg-grey-young"
                      key={index}
                    >
                      <div className="w-12 h-12 border border-primary rounded-full overflow-hidden">
                        <img
                          src={coach.photo}
                          alt={coach.name}
                          className="w-full "
                        />
                      </div>

                      <div>
                        <h1 className="font-semibold text-sm pr-4">
                          {coach.name}
                        </h1>
                        <p className="text-sm">{coach.type}</p>
                      </div>
                      <GoTrashcan
                        className="text-danger text-2xl absolute bottom-3 right-3 cursor-pointer"
                        onClick={() => handleDeleteCoachIsPick(coach.id)}
                      />
                    </div>
                  ))
                : ""}
            </div>
            {coachData.length == 0 && (
              <div className="lg:w-[40rem] w-full bg-bg-grey-young h-[5rem] rounded-lg flex justify-center items-center font-medium text-slate-300">
                <h5>No coach yet</h5>
              </div>
            )}
            <div className="flex item-center gap-2">
              <Link type="submit" to="/company" className="button bg-danger">
                Cancel
              </Link>
              <button
                type="submit"
                className="button bg-success "
                onClick={handleSaveCompany}
              >
                Save Company
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddCompany;
